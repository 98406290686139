<template>
    <div class="details">
        <!-- Project Section -->
        <images2 :images="['ingredientodyssey/io_1.png', 'ingredientodyssey/io_2.png']"></images2>
         <!------------------ABOUT------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-150px padding-bottom-150px">

            <div class="container">

            <!-- Row -->
            <div class="row justify-content-center" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

                <!-- Column -->
                <div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

                <h2 class="text-weight-100 text-gray-extra-dark margin-bottom-35px"><strong>Design da marca</strong> <br>Ingredient Odyssey - Investigação e Desenvolvimento</h2>

                </div>
                <!-- End Column -->

            </div>
            <!-- End Row -->

            </div>
            <!-- End Container -->

        </div>
         <!------------------ABOUT------------------------------------------------->
        <full-image :images="['ingredientodyssey/io_3.jpg']"></full-image>
        <images2 :images="['ingredientodyssey/io_4.png', 'ingredientodyssey/io_5.jpg']"></images2>
        <project-details project="Design de marca, Design gráfico" client="Ingredient Odyssey" date="2019" country="Portugal"></project-details>
        
        <full-image :images="['ingredientodyssey/io_6.jpg']"></full-image>
        <!------------------DESC------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="margin-bottom-150px margin-top-150px">
            <div class="container">
                <div class="row" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">
                    <div class="col-md-12 col-sm-12 col-xs-12 center-col text-center">
                        <h5 class="text-weight-500 text-gray-extra-dark">O ADN de uma marca</h5>
                        <div class="width-100 sm-width-100">
                            <p class="text-gray-dark">A Ingredient Odyssey, tem no seu ADN a sustentabilidade ambiental, através do desenvolvimento de alternativas proteicas para a alimentação humana e animal.<br>
                            A ideia por detrás da imagem desenvolvida remete aos tempos das descobertas, quando os portugueses percorriam o mundo nas suas caravelas em busca dos alimentos e especiarias mais raras e valiosas. Assim nasce a marca gráfica , em formato emblema, como um selo de qualidade, inovação e espirito empreendedor.</p>
                        </div>
                    </div>
                </div>
            </div>
	    </div>
        <!------------------DESC------------------------------------------------->
        <div class="pager text-center margin-top-150px">
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px"> PROJETO Anterior</a>
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px">PROJETO Seguinte</a>
            <!-- <router-link :to="prevProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Anterior</router-link>
            <router-link :to="nextProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Próximo</router-link> -->
        </div>
      
        <footer-main-component></footer-main-component>
    </div>
</template>

<script>
import FooterMainComponent from '../FooterMainComponent.vue'
import Images2 from './types/2images.vue'
import Images3 from './types/3images.vue'
import ProjectDetails from './types/details.vue'
import FullImage from './types/fullimage.vue'


export default {
  name: 'Details',
  components: {
    FooterMainComponent,
    Images2,
    Images3,
    ProjectDetails,
    FullImage
  },
  metaInfo () {
    return {
	 title: 'Portfólio MYLOGO – Design da marca Ingredient Odyssey - Investigação e Desenvolvimento',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Ingredient Odyssey - Investigação e Desenvolvimento.'
      }]
    }
  }
}
</script>
