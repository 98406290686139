<template>
    <div class="details">
        <!-- Project Section -->
        <full-image :images="['honest/HonestTurtle_1.jpg']"></full-image>
         <!------------------ABOUT------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-150px padding-bottom-150px">

            <div class="container">

            <!-- Row -->
            <div class="row justify-content-center" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

                <!-- Column -->
                <div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

                <h2 class="text-weight-100 text-gray-extra-dark margin-bottom-35px"><strong>Design da marca</strong> para a empresa de audiovisuais Honest Turtle</h2>

                </div>
                <!-- End Column -->

            </div>
            <!-- End Row -->

            </div>
            <!-- End Container -->

        </div>
        <!------------------ABOUT------------------------------------------------->
        <images2 :images="['honest/HonestTurtle_2.jpg', 'honest/HonestTurtle_3.jpg']"></images2>
        <project-details project="Design de marca, Design gráfico" client="Honest Turtle" date="2021" country="Portugal"></project-details>
        <images2 :images="['honest/HonestTurtle_4.jpg', 'honest/HonestTurtle_5.jpg']"></images2>
        <full-image :images="['honest/HonestTurtle_6.jpg']"></full-image>
        <!------------------DESC------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="margin-bottom-150px margin-top-150px">
            <div class="container">
                <div class="row" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">
                    <div class="col-md-12 col-sm-12 col-xs-12 center-col text-center">
                        <h5 class="text-weight-500 text-gray-extra-dark">Uma Tartaruga Amigável com um Toque Criativo</h5>
                        <div class="width-100 sm-width-100">
                            <p class="text-gray-dark">Este logotipo é mais do que uma simples imagem; é a expressão visual da Honest Turtle e dos valores que defendem. Representa a nossa dedicação à honestidade, à qualidade e à criatividade em todos os projetos que empreendem.<br>
                            A figura central do logotipo é uma tartaruga, um animal conhecido pela sua honestidade e determinação ao longo da jornada. A escolha das cores, laranja e preto, não foi por acaso. O laranja simboliza a energia criativa e a paixão que dedicam ao nosso trabalho, enquanto o preto representa a sofisticação e a qualidade dos serviços.<br>
                            A carapaça da tartaruga assume a forma de uma máquina de filmar, incorporando o compromisso com a excelência em produção audiovisual. Essa fusão de elementos naturais com tecnologia reflete uma abordagem única e inovadora no setor.</p>
                        </div>
                    </div>
                </div>
            </div>
	    </div>
        <!------------------DESC------------------------------------------------->
        <div class="pager text-center margin-top-150px">
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px"> PROJETO Anterior</a>
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px">PROJETO Seguinte</a>
            <!-- <router-link :to="prevProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Anterior</router-link>
            <router-link :to="nextProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Próximo</router-link> -->
        </div>
      
        <footer-main-component></footer-main-component>
    </div>
</template>

<script>
import FooterMainComponent from './../FooterMainComponent.vue'
import Images2 from './../projects/types/2images.vue'
import Images3 from './../projects/types/3images.vue'
import ProjectDetails from './../projects/types/details.vue'
import FullImage from './../projects/types/fullimage.vue'


export default {
  name: 'Details',
  components: {
    FooterMainComponent,
    Images2,
    Images3,
    ProjectDetails,
    FullImage
  },
  metaInfo () {
    return {
	 title: 'Portfólio MYLOGO – Design da marca  para a Honest Turtle - Audiovisuais',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Página de portfólio da MYLOGO. Conheça o nosso trabalho de criação de marca para a Honest Turtle. Uma Tartaruga Amigável com um Toque Criativo.'
      }]
    }
  }
}
</script>
