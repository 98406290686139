<template>
    <div data-uk-scrollspy="cls:uk-animation-fade">
        <!-- Container -->
        <div class="container-full">
            <!-- Row -->
            <div class="row justify-content-center">
                
                <div class="works uk-child-width-1-1@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">
                    <!-- Work -->
                    <div class="images" data-uk-lightbox="animation: fade">

                        <a :href="'../images/works/'+images[0]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

                            <!-- Image -->
                            <img :src="'../images/works/'+images[0]" class="row-wide" alt=""  />

                        </a>
                        <!-- End Work -->

                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
  props: ['images'],
  data () {
    return {
      
    }
  },
  created () {
  },
  
}
</script>