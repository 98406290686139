<template>
    <div class="details">
        <!-- Project Section -->
        <images2 :images="['essence/essence1.jpg', 'essence/essence2.jpg']"></images2>
         <!------------------ABOUT------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-150px padding-bottom-150px">

            <div class="container">

            <!-- Row -->
            <div class="row justify-content-center" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

                <!-- Column -->
                <div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

                <h2 class="text-weight-100 text-gray-extra-dark margin-bottom-35px"><strong>Design da marca</strong> Essence - Perfumaria</h2>

                </div>
                <!-- End Column -->

            </div>
            <!-- End Row -->

            </div>
            <!-- End Container -->

        </div>
        <full-image :images="['essence/essence3.jpg']"></full-image>
        <full-image :images="['essence/essence4.jpg']"></full-image>
        <project-details project="Design de marca, Packaging design, design gráfico, web design" client="Essence" date="Desde 2014" country="Angola"></project-details>
        <images2 :images="['essence/essence5.jpg', 'essence/essence6.jpg']"></images2>
        <images2 :images="['essence/essence7.jpg', 'essence/essence8.jpg']"></images2>
        <!------------------DESC------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="margin-bottom-150px margin-top-150px">
            <div class="container">
                <div class="row" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">
                    <div class="col-md-12 col-sm-12 col-xs-12 center-col text-center">
                        <h5 class="text-weight-500 text-gray-extra-dark">A marca que é o produto.</h5>
                        <div class="width-100 sm-width-100">
                            <p class="text-gray-dark">Para a Essence que oferece ao consumidor perfumes de qualidade a preço acessível, a MYLOGO, desenvolveu uma logotipo tipográfico, minimalista, alusivo ao frasco de perfume que tanto se coloca ao alto, como deitado sobre uma prateleira. Os dois SS representam a onda da fragrância que se espalha pelo ar.<br>
Toda marca foi trabalhada de um modo minimalista, mas impactante, com tons nobres dados pelo dourado em sobreposição à neutralidade dos brancos e pretos.<br>
Uma marca que muda, transforma-se e adapta-se à evolução dos tempos.</p>
                        </div>
                    </div>
                </div>
            </div>
	    </div>
        <images2 :images="['essence/essence11.jpg', 'essence/essence12.jpg']"></images2>
        <!------------------DESC------------------------------------------------->
        <div class="pager text-center margin-top-150px">
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px"> PROJETO Anterior</a>
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px">PROJETO Seguinte</a>
            <!-- <router-link :to="prevProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Anterior</router-link>
            <router-link :to="nextProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Próximo</router-link> -->
        </div>
      
        <footer-main-component></footer-main-component>
    </div>
</template>

<script>
import FooterMainComponent from '../FooterMainComponent.vue'
import Images2 from './types/2images.vue'
import Images3 from './types/3images.vue'
import ProjectDetails from './types/details.vue'
import FullImage from './types/fullimage.vue'


export default {
  name: 'Details',
  components: {
    FooterMainComponent,
    Images2,
    Images3,
    ProjectDetails,
    FullImage
  },
  metaInfo () {
    return {
	 title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Essence - Perfumaria',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Essence.'
      }]
    }
  }
}
</script>
