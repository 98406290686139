<template>
    <div>

		<!-- About -->
		<section id="link1" class="padding-top-bottom-150px md-padding-top-bottom-70px">

			<!-- Container -->
			<div class="container" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > .row > div ; delay: 50; repeat: false">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

						<h2 class="text-weight-300 text-black">Move-nos a paixão<br> <span class="text-weight-500">Pelo bom, o belo e o funcional.</span></h2>

						<p>Somos uma equipa multidisciplinar experiente e dinâmica, que actua nas várias áreas do design. Não trabalhamos num escritório. Privilegiamos a autonomia, liberdade e responsabilidade de cada um dos nossos colaboradores, fomentando uma permanente comunicação com o cliente. Somos movidos por uma enorme paixão, e estaremos sempre focados no que julgamos ser as melhores opções de comunicação para cada negócio.</p>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</section>
		<!-- End About -->

		<!-- Stats -->
		<section class="bg-gray-extra-light">

			<!-- Container -->
			<div class="container-full" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > .row > div ; delay: 50; repeat: false">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-md-6 col-sm-12 col-xs-12 center-col text-center no-padding margin-auto image-bg-mylogo-1">

						<img src="images/backgrounds/mylogo.jpg" alt="" />

					</div>
					<!-- End Column -->

					<!-- Column -->
					<div class="col-md-6 col-sm-12 col-xs-12 center-col bg-gray-extra-light text-left padding-top-150px padding-bottom-150px md-padding-top-bottom-70px margin-auto sm-text-center">

						<div class="row">

							<div class="col-md-8 col-sm-12 col-xs-12 center-col">

								<div class="container">

									<div class="">

										<h2 class="text-weight-300 text-black line-height-1">Direção <br><span class="text-weight-500">Criativa</span></h2>
										<p><strong>Joana Pestana, Designer gráfica</strong></p>
										<p>Olá,<br>
											O meu nome é Joana Pestana e sou fundadora da MYLOGO.<br><br>
											Designer de formação, a minha paixão é a criação de logotipos.<br>
											Para mim é a peça gráfica por excelência e a mais exigente. Imaginem uma tela em branco, pronta para ser preenchida....tudo é possível. Numa imagem simples, única e criativa, conseguir resumir toda a essência de uma empresa é um desafio...e é esse desafio que me atrai.
											É com enorme alegria que abraço cada projeto.
										</p>

									</div>

								</div>

							</div>

						</div>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</section>
		<!-- End Stats -->

		<!-- Countdown Style 03 -->
		<section data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-bottom-150px md-padding-top-bottom-70px bg-gray-extra-light">

			<!-- Container -->
			<div class="container">

				<!-- Animation -->
				<div data-uk-scrollspy="cls: uk-animation-scale-up; target: > .row > div ; delay: 50; repeat: false">

					<!-- Row -->
					<div class="row">

						<!-- Counter -->
						<div class="col-md-4 col-sm-6 col-xs-12 counter-box text-center sm-margin-bottom-25px xs-margin-bottom-75px">

							<h1 class="timer text-gray-dark text-gray-dark title-large text-weight-200 no-margin" data-speed="500" data-to="12">12</h1>

							<p class="text-gray-extra-dark text-uppercase text-center text-weight-500 letter-spacing-2 margin-top-20px no-margin-bottom barlow-condensed">ANOS DE MYLOGO, MAS MUITOS MAIS DE EXPERIÊNCIA.</p>

						</div>
						<!-- End Counter -->

						<!-- Counter -->
						<div class="col-md-4 col-sm-6 col-xs-12 counter-box text-center xs-margin-bottom-75px">

							<h1 class="timer text-gray-dark text-gray-dark title-large text-weight-200 no-margin" data-speed="1500" data-to="800">800</h1>

							<p class="text-gray-extra-dark text-uppercase text-center text-weight-500 letter-spacing-2 margin-top-20px no-margin-bottom barlow-condensed">IDENTIDADES CRIADAS COM IGUAL DEDICAÇÃO</p>

						</div>
						<!-- End Counter -->

						<!-- Counter -->
						<div class="col-md-4 col-sm-6 col-xs-12 counter-box text-center">

							<h1 class="timer text-gray-dark text-gray-dark title-large text-weight-200 no-margin" data-speed="1500" data-to="950">950</h1>

							<p class="text-gray-extra-dark text-uppercase text-center text-weight-500 letter-spacing-2 margin-top-20px no-margin-bottom barlow-condensed">CLIENTES QUE SE TORNARAM AMIGOS. OBRIGADO!</p>

						</div>
						<!-- End Counter -->

					</div>
					<!-- End Row -->

				</div>
				<!-- End Animation -->

			</div>
			<!-- End Container -->

		</section>
		<!-- End Countdown Style 03 -->

        <!-- Call to Action -->
		<section class="padding-top-bottom-150px md-padding-top-bottom-70px bg-mylogo">

			<!-- Container -->
			<div class="container" data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > .row > div ; delay: 50; repeat: false">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-md-12 col-sm-12 col-xs-12 center-col text-center margin-auto">

						<h5 class="montserrat text-weight-600 text-black no-margin-bottom">Tem um projeto em mente para nós concretizarmos? <router-link :to="{ name: 'Contact' }" class="btn btn-medium btn-black border-radius-50 margin-left-20px md-margin-top-35px sm-margin-left-right-auto sm-display-table">Contacte-nos</router-link></h5>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</section>
		<!-- End Call to Action -->

		<footer-main-component></footer-main-component>

    </div>
</template>

<script>
import FooterMainComponent from './FooterMainComponent.vue'

export default {
  components: {
    FooterMainComponent
  },
  metaInfo: {
    title: 'A MYLOGO. Especialistas em logotipos. Move-nos a paixão.',
    meta: [
      { charset: 'utf-8' },
      { vmid: 'description', name: 'description', content: 'Move-nos a paixão. Equipa multidisciplinar experiente e dinâmica , que actua nas várias áreas do design.Optamos pelas melhores opções para cada negócio' }
    ]
  }
}
</script>
