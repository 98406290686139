<template>
    
    <div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-bottom-150px">

        <!-- Container -->
        <div class="container">

            <!-- Row -->
            <div class="row" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

                <!-- Column -->
                <div class="col-md-3 col-sm-12 col-xs-12 center-col text-center margin-auto sm-margin-bottom-50px">

                    <h6 class="text-weight-600 text-extra-large text-gray-extra-dark no-margin-bottom">Projecto</h6>

                    <!-- Separator -->
                    <div class="separator width-15 center-col bottom-border border-1px border-color-gray-dark margin-top-bottom-30px"></div>

                    <p class="text-extra-large-3 text-weight-300 text-gray-extra-dark no-margin-bottom barlow-condensed">{{ project }}</p>

                </div>
                <!-- End Column -->

                <!-- Column -->
                <div class="col-md-3 col-sm-12 col-xs-12 center-col text-center margin-auto sm-margin-bottom-50px">

                    <h6 class="text-weight-600 text-extra-large text-gray-extra-dark no-margin-bottom">Cliente</h6>

                    <!-- Separator -->
                    <div class="separator width-15 center-col bottom-border border-1px border-color-gray-dark margin-top-bottom-30px"></div>

                    <p class="text-extra-large-3 text-weight-300 text-gray-extra-dark no-margin-bottom barlow-condensed">{{ client }}</p>

                </div>
                <!-- End Column -->

                <!-- Column -->
                <div class="col-md-3 col-sm-12 col-xs-12 center-col text-center margin-auto sm-margin-bottom-50px">

                    <h6 class="text-weight-600 text-extra-large text-gray-extra-dark no-margin-bottom">Data</h6>

                    <!-- Separator -->
                    <div class="separator width-15 center-col bottom-border border-1px border-color-gray-dark margin-top-bottom-30px"></div>

                    <p class="text-extra-large-3 text-weight-300 text-gray-extra-dark no-margin-bottom barlow-condensed">{{ date }}</p>

                </div>
                <!-- End Column -->

                <!-- Column -->
                <div class="col-md-3 col-sm-12 col-xs-12 center-col text-center margin-auto">

                    <h6 class="text-weight-600 text-extra-large text-gray-extra-dark no-margin-bottom">País</h6>

                    <!-- Separator -->
                    <div class="separator width-15 center-col bottom-border border-1px border-color-gray-dark margin-top-bottom-30px"></div>

                    <p class="text-extra-large-3 text-weight-300 text-gray-extra-dark no-margin-bottom barlow-condensed">{{country}}</p>

                </div>
                <!-- End Column -->

            </div>
            <!-- End Row -->

        </div>
        <!-- End Container -->

    </div>

</template>

<script>

export default {
  props: ['project', 'client', 'date', 'country'],
  data () {
    return {
      
    }
  },
  created () {
  },
  
}
</script>