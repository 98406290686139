import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './components/Home'
import Mylogo from './components/Mylogo'
import Services from './components/Services'
import Portfolio from './components/Portfolio'
import Contact from './components/Contact'
import Details from './components/Details'
import Page404 from './components/Page404'
import Success from './components/Success'
import Erro from './components/Erro'
import Cal from './components/projects/Cal'
import Piriquita from './components/projects/Piriquita'
import Marcha from './components/projects/Marcha'
import Green from './components/projects/Green'
import BooksFactory from './components/projects/Books'
import HonestTurtle from './components/projects/Honest'
import Aiwama from './components/projects/Aiwama'
import Essence from './components/projects/Essence'
import Bonabebe from './components/projects/Bonabebe'
import Bazza from './components/projects/Bazza'
import Canal from './components/projects/Canal'
import Ingredient from './components/projects/Ingredient'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production'
    ? '/dev/mylogo/'
    : '/',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/mylogo',
      name: 'Mylogo',
      component: Mylogo
    },
    {
      path: '/servicos',
      name: 'Services',
      component: Services
    },
    {
      path: '/portfolio',
      name: 'Portfolio',
      component: Portfolio
    },
    {
      path: '/portfolio/cal',
      name: 'Cal',
      component: Cal,
      props: true
    },
    {
      path: '/portfolio/piriquita',
      name: 'Piriquita',
      component: Piriquita,
      props: true
    },
    {
      path: '/portfolio/marcha-da-mouraria',
      name: 'Marcha-da-mouraria',
      component: Marcha,
      props: true
    },
    {
      path: '/portfolio/green-pine',
      name: 'green-pine',
      component: Green,
      props: true
    },
    {
      path: '/portfolio/booksfactory',
      name: 'booksfactory',
      component: BooksFactory,
      props: true
    },
    {
      path: '/portfolio/honest-turtle',
      name: 'honest-turtle',
      component: HonestTurtle,
      props: true
    },
    {
      path: '/portfolio/aiwama',
      name: 'aiwama',
      component: Aiwama,
      props: true
    },
    {
      path: '/portfolio/essence',
      name: 'essence',
      component: Essence,
      props: true
    },
    {
      path: '/portfolio/bonabebe',
      name: 'bonabebe',
      component: Bonabebe,
      props: true
    },
    {
      path: '/portfolio/bazza',
      name: 'bazza',
      component: Bazza,
      props: true
    },
    {
      path: '/portfolio/canal-cultura',
      name: 'canal-cultura',
      component: Canal,
      props: true
    },
    {
      path: '/portfolio/ingredient-odyssey',
      name: 'ingredient-odyssey',
      component: Ingredient,
      props: true
    },
    {
      path: '/portfolio/:slug',
      name: 'Details',
      component: Details,
      props: true
    },
    {
      path: '/contactos',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/sucesso',
      name: 'Success',
      component: Success
    },
    {
      path: '/erro',
      name: 'Error',
      component: Erro
    },
    { path: '*', component: Page404 }
  ]
})

router.afterEach((to, from, next) => {
  document.querySelector('html').classList.remove('uk-offcanvas-page')
  document.querySelector('html').removeAttribute('style')
  document.querySelector('body').removeAttribute('class')
  if (document.getElementById('offcanvas-nav')) {
    document.getElementById('offcanvas-nav').classList.remove('.uk-offcanvas-overlay.uk-open')
    document.getElementById('offcanvas-nav').removeAttribute('style')
  }
})

export default router
