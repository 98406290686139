<template>
    <!-- Header Dark -->
	<header>
		<div class="z-index-999">

			<!-- Header Options -->
			<div class="navbar-white" data-uk-sticky>

				<!-- Header Container -->
				<nav class="uk-navbar-container" data-uk-navbar="boundary-align: true; align: center;">

					<!-- Navigation Left Side -->
					<div class="uk-navbar-left padding-left-four-percent">

						<!-- Logo Image -->
						<a href="/" class="uk-navbar-item uk-logo"><img src="../assets/logo.png" alt="" /></a>

						<!-- <router-link :to="{ name: 'Home' }" class="uk-navbar-item uk-logo" data-uk-scroll exact><img src="../assets/logo.png" alt="" /></router-link> -->

					</div>
					<!-- End Navigation Left Side -->

					<!-- Navigation Right Side -->
					<div class="uk-navbar-right uk-dark padding-right-four-percent">

						<!-- Links -->
						<ul class="uk-navbar-nav text-weight-600">

							<!-- Off-Canvas Navigation Toggle -->
							<li>

								<div class="uk-navbar-left uk-hidden@l">

									<a class="uk-navbar-toggle" data-uk-navbar-toggle-icon data-uk-toggle="target: #offcanvas-nav"></a>

								</div>

							</li>
							<!-- End Off-Canvas Navigation Toggle -->

							<li>
								<a :class="[ menuActive === '' ? 'router-link-exact-active': '']" class="text-black text-extra-small uk-visible@l" href="/">Home</a>
								<!-- <router-link :to="{ name: 'Home' }" class="text-black text-extra-small uk-visible@l" exact>Home</router-link> -->
							</li>
							<!-- Link -->
							<li>
								<a :class="[ menuActive === 'mylogo' ? 'router-link-exact-active': '']" class="text-black text-extra-small uk-visible@l" href="/mylogo">A MYLOGO</a>
								<!-- <router-link :to="{ name: 'Mylogo' }" class="text-black text-extra-small uk-visible@l">A MYLOGO</router-link> -->
							</li>
							<!-- End Link -->
							<!-- Link -->
							<li>
								<a :class="[ menuActive === 'servicos' ? 'router-link-exact-active': '']" class="text-black text-extra-small uk-visible@l" href="/servicos">Serviços</a>
								<!-- <router-link :to="{ name: 'Services' }" class="text-black text-extra-small uk-visible@l">Serviços</router-link> -->
							</li>
							<!-- End Link -->
							<!-- Link -->
							<li>
								<a :class="[ menuActive === 'portfolio' ? 'router-link-exact-active': '']" class="text-black text-extra-small uk-visible@l" href="/portfolio">Portfolio</a>
								<!-- <router-link :to="{ name: 'Portfolio' }" class="text-black text-extra-small uk-visible@l">Portfolio</router-link> -->
							</li>
							<!-- End Link -->
							<!-- Link -->
							<li>
								<a :class="[ menuActive === 'contactos' ? 'router-link-exact-active': '']" class="text-black text-extra-small uk-visible@l" href="/contactos">Contactos</a>
								<!-- <router-link :to="{ name: 'Contact' }" class="text-black text-extra-small uk-visible@l">Contactos</router-link> -->
							</li>
							<!-- End Link -->

							<li><a class="uk-visible@l" href="https://www.facebook.com/MYLOGOdesign" target="_blank"><i class="fab fa-facebook email"></i></a></li>
							<li><a class="uk-visible@l" href="https://www.instagram.com/mylogo_design/" target="_blank"><i class="fab fa-instagram email"></i></a></li>
							<li><a class="uk-visible@l" href="#"><i class="fab fa-linkedin email"></i></a></li>
							<li><a class="uk-visible@l" href="mailto:geral@mylogo.pt"><i class="far fa-envelope-open email"></i></a></li>

						</ul>
						<!-- End Links -->

					</div>
					<!-- End Navigation Right Side -->

				</nav>
				<!-- End Header Container -->

			</div>
			<!-- End Header Options -->

		</div>
		<!-- End Header Dark -->
		<!-- Off-Canvas Navigation -->
		<div id="offcanvas-nav" data-uk-offcanvas="mode: push; overlay: true; esc-close: true;">

			<!-- Off-Canvas Bar -->
			<div class="uk-offcanvas-bar menu-dark bg-white">

				<!-- Off-Canvas Links-->
				<ul class="uk-nav-default text-left" data-uk-nav>

					<li>
						<a href="/" class="uk-navbar-item uk-logo"><img src="../assets/logo.png" alt="" /></a>
						<!-- <router-link :to="{name: 'Home'}" class="uk-navbar-item uk-logo" href="#" data-uk-scroll exact><img src="../assets/logo.png" alt="" /></router-link> -->
					</li>

					<!-- Link -->
					<li class="margin-bottom-10px">

						<a href="/" :class="[ menuActive === '' ? 'router-link-exact-active': '']" class="border-1px border-color-gray-extra-dark"><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">Home</span></a>
						<!-- <router-link :to="{name: 'Home'}" class="border-1px border-color-gray-extra-dark" exact><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">Home</span></router-link> -->

					</li>
					<!-- End Link -->

					<!-- Link -->
					<li class="margin-bottom-10px">

						<a href="/mylogo" :class="[ menuActive === 'mylogo' ? 'router-link-exact-active': '']" class="border-1px border-color-gray-extra-dark"><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">A MYLOGO</span></a>
						<!-- <router-link :to="{name: 'Mylogo'}" class="border-1px border-color-gray-extra-dark"><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">A MYLOGO</span></router-link> -->

					</li>
					<!-- End Link -->

					<!-- Link -->
					<li class="margin-bottom-10px">

						<a href="/servicos" :class="[ menuActive === 'servicos' ? 'router-link-exact-active': '']" class="border-1px border-color-gray-extra-dark"><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">Serviços</span></a>
						<!-- <router-link :to="{name: 'Services'}" class="border-1px border-color-gray-extra-dark"><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">Serviços</span></router-link> -->

					</li>
					<!-- End Link -->
					<!-- Link -->
					<li class="margin-bottom-10px">
						<a href="/portfolio" :class="[ menuActive === 'portfolio' ? 'router-link-exact-active': '']" class="border-1px border-color-gray-extra-dark"><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">Portfolio</span></a>
						<!-- <router-link :to="{name: 'Portfolio'}" class="border-1px border-color-gray-extra-dark"><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">Portfolio</span></router-link> -->

					</li>
					<!-- End Link -->
					<!-- Link -->
					<li class="margin-bottom-10px">
						<a href="/contactos" :class="[ menuActive === 'portfolio' ? 'router-link-exact-active': '']" class="border-1px border-color-gray-extra-dark"><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">Contactos</span></a>
						<!-- <router-link :to="{name: 'Contact'}" class="border-1px border-color-gray-extra-dark"><span class="sm-text-medium text-dark text-uppercase text-weight-600 padding-bottom-20px barlow-condensed">Contactos</span></router-link> -->

					</li>
					<!-- End Link -->

				</ul>
				<!-- End Off-Canvas Links -->

				<!-- Navigation Footer -->
				<div class="nav-footer margin-top-100px">

					<!-- End Off-Canvas Search -->

					<!-- Social Links for Off-Canvas Footer -->
					<ul class="list-unstyled no-margin-bottom margin-top-20px">

						<li class="display-inline-block margin-right-25px"><a href="https://www.facebook.com/MYLOGOdesign" target="_blank"><i class="fab fa-facebook email"></i></a></li>
						<li class="display-inline-block margin-right-25px"><a href="https://www.instagram.com/mylogo_design/" target="_blank"><i class="fab fa-instagram email"></i></a></li>
						<li class="display-inline-block margin-right-25px"><a href="#"><i class="fab fa-linkedin email"></i></a></li>
						<li class="display-inline-block margin-right-25px"><a href="mailto:geral@mylogo.pt"><i class="far fa-envelope-open email"></i></a></li>

					</ul>
					<!-- End Social Links for Off-Canvas Footer -->

					<!-- Copyright Information -->
					<!-- <p class="no-margin-bottom margin-top-20px text-small text-gray-light text-weight-400">Copyright © 2019 of <a href="https://www.templatemilk.com" target="_blank">templatemilk.</a></p>
					<p class="no-margin-bottom text-small text-gray-light text-weight-400 text-black">All rights reserved.</p> -->
					<!-- End Copyright Information -->

				</div>
				<!-- End Navigation Footer -->

			</div>
			<!-- End Off-Canvas Bar -->

		</div>
		<!-- End Off-Canvas Navigation -->
		<div class="fixed-whats">
			<a href="https://api.whatsapp.com/send?phone=+351918662005" @click="whatsAppConversion()">
				<img alt="" src="/images/whatsapp.svg" class="img-whatsapp" width="100" />
				<img alt="" src="/images/whatsapp-mobile.svg" class="img-whatsapp-mobile"/>
			</a>
		</div>
	</header>
</template>

<script>

export default ({
  data () {
    return {
      menuActive: ''
    }
  },
  methods: {
	  whatsAppConversion: function () {
		  window.gtag_report_conversion()
	  }
  },
  mounted () {

  },
  created () {
    this.menuActive = window.location.href.split('/')[3]
  }
})
</script>
