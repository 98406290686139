<template>
    <div>

		<!-- Page Title -->
		<div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-150px md-padding-top-70px">

			<!-- Container -->
			<div class="container">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-lg-8 col-md-6 col-sm-12 col-xs-12 display-table">

						<!-- Alignment -->
						<div class="vertical-align-middle display-table-cell text-left sm-text-center">

							<!-- Title -->
							<h1 class="text-black text-weight-600">Contacto</h1>

							<!-- Separator -->
							<div class="separator width-30px bottom-border border-2px border-color-gray-light margin-top-bottom-20px sm-margin-left-right-auto"></div>

							<h6 class="no-margin text-black text-weight-600 no-margin-bottom">Queremos conhecer o seu projeto.</h6>

						</div>
						<!-- End Alignment -->

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Page Title -->

		<!-- Contact -->
		<div class="padding-top-bottom-150px md-padding-top-bottom-70px">

			<!-- Container -->
			<div class="container">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-md-12 col-sm-12 col-xs-12 center-col text-center">

						<!-- Contact Container -->
						<div class="contact-wrapper">

							<!-- Form Container -->
							<form class="contact-form barlow-condensed" method="post" action="contact.php">

								<!-- Messages For The User -->
								<div class="messages"></div>

								<!-- Form Controls -->
								<div class="controls">

									<!-- Row -->
									<div class="row">

										<!-- Column -->
										<div class="col-md-12 col-sm-12 col-xs-12">

											<!-- Form Group -->
											<div class="form-group">

												<!-- Input -->
												<input class="form-control form_name no-margin-top padding-20px text-uppercase text-small text-gray-extra-dark text-weight-800 letter-spacing-1 montserrat" type="text" name="name"  placeholder="Nome *" required="required" data-error="Nome Obrigatório.">

												<!-- Error Block -->
												<div class="help-block with-errors"></div>

											</div>
											<!-- End Form Group -->

										</div>
										<!-- End Column -->

									</div>
									<!-- End Row -->

									<!-- Row -->
									<div class="row">

										<!-- Column -->
										<div class="col-md-12">

											<!-- Form Group -->
											<div class="form-group">

												<!-- Input -->
                                                <input class="form-control form_email no-margin-top padding-20px text-uppercase text-small text-gray-extra-dark text-weight-800 letter-spacing-1 montserrat" type="email" name="email" placeholder="E-mail *" required="required" data-error="E-mail Obrigatório">
												<!-- Error Block -->
												<div class="help-block with-errors"></div>

											</div>
											<!-- End Form Group -->

										</div>
										<!-- End Column -->

									</div>
									<!-- End Row -->

									<!-- Row -->
									<div class="row">

										<!-- Column -->
										<div class="col-md-6">

											<!-- Form Group -->
											<div class="form-group">

												<!-- Input -->
												<input class="form-control form_lastname no-margin-top padding-20px text-uppercase text-small text-gray-extra-dark text-weight-800 letter-spacing-1 montserrat" type="text" name="surname" placeholder="Localidade *" required="required" data-error="Localidade Obrigatória">

												<!-- Error Block -->
												<div class="help-block with-errors"></div>

											</div>
											<!-- End Form Group -->

										</div>
										<!-- End Column -->

										<!-- Column -->
										<div class="col-md-6">

											<!-- Form Group -->
											<div class="form-group">

												<!-- Input -->
												<input class="form-control form_phone no-margin-top padding-20px text-uppercase text-small text-gray-extra-dark text-weight-800 letter-spacing-1 montserrat" type="tel" name="phone" placeholder="Telefone" required="required" data-error="Telefone Obrigatório">

												<!-- Error Block -->
												<div class="help-block with-errors"></div>

											</div>
											<!-- End Form Group -->

										</div>
										<!-- End Column -->

									</div>
									<!-- End Row -->

									<!-- Row -->
									<div class="row">

										<!-- Column -->
										<div class="col-md-12">

											<!-- Form Group -->
											<div class="form-group">
												<div class="text-left text-weight-800 letter-spacing-1 text-small montserrat text-uppercase"><label>Como conheceu a MYLOGO?</label></div>
												<!-- Input -->
												<select class="form-control form_message text-uppercase text-small text-weight-800 letter-spacing-1 montserrat" name="know" required="required" data-error="Campo Obrigatório" v-model="selectedOption">
													<option hidden disabled selected value></option>
													<option value="web">Anúncio na web</option>
													<option value="redes">Redes sociais</option>
													<option value="recomendação">Recomendação de amigo/colega</option>
													<option value="cliente">Já fui cliente</option>
													<option value="outro">Outro</option>
												</select>

											</div>
											<!-- End Form Group -->

											<!-- Form Group -->
											<div class="form-group" v-if="selectedOption === 'outro'">
												
												<input class="form-control form_phone no-margin-top padding-20px text-uppercase text-small text-gray-extra-dark text-weight-800 letter-spacing-1 montserrat" type="text" name="other" placeholder="Outro">

											</div>
											<!-- End Form Group -->

										</div>
										<!-- End Column -->


									</div>
									<!-- End Row -->

									<!-- Row -->
									<div class="row">

										<!-- Column -->
										<div class="col-md-12">

											<!-- Form Group -->
											<div class="form-group">

												<!-- Input -->
												<textarea class="form-control form_message no-margin-top padding-20px text-uppercase text-small text-gray-extra-dark text-weight-800 letter-spacing-1 montserrat" name="message" placeholder="Conte-nos sobre o seu projeto *" rows="4" required="required" data-error="Mensagem Obrigatória"></textarea>

												<!-- Error Block -->
												<div class="help-block with-errors"></div>

											</div>
											<!-- End Form Group -->

										</div>
										<!-- End Column -->

										<!-- Column -->
										<div class="col-md-12">

											<!-- Input -->
											<input type="submit" class="btn-send btn btn-large btn-gray-extra-dark border-radius-50 md-margin-bottom-15px sm-margin-left-right-auto sm-display-table" value="Enviar Mensagem">

										</div>
										<!-- End Column -->

									</div>
									<!-- End Row -->

								</div>
								<!-- End Form Controls -->

							</form>
							<!-- End Form Container -->

						</div>
						<!-- END Contact Container -->

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Contact -->

		<footer-main-component></footer-main-component>

    </div>
</template>

<script>
import FooterMainComponent from './FooterMainComponent.vue'

export default {
  components: {
    FooterMainComponent
  },
  data() {
    return {
      selectedOption: ''
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Contactos',
    // all titles will be injected into this template
    titleTemplate: '%s - Mylogo'
  }
}
</script>
