<template>
    <div class="details">
		<!-- Project Section -->
		<div data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container Type 1-->
			<div v-if="filterProject[0].type === 1 || filterProject[0].type === 2 || filterProject[0].type === 4 || filterProject[0].type === 7 || filterProject[0].type === 10 || filterProject[0].type === 12 || filterProject[0].type === 15 || filterProject[0].type === 18 || filterProject[0].type === 20 || filterProject[0].type === 22" class="container-full">

				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="projects" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[0]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[0]" class="row-wide" :alt="filterProject[0].title" />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>
							<!-- End Work -->

						</div>

					</div>

				</div>

			</div>
			<!-- End Container -->
			<!-- Container Type 2-->
			<div v-if="filterProject[0].type === 3 || filterProject[0].type === 5 || filterProject[0].type === 6 || filterProject[0].type === 8 || filterProject[0].type === 9 || filterProject[0].type === 11 || filterProject[0].type === 13 || filterProject[0].type === 14 || filterProject[0].type === 16" class="container-full">

				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[0]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[0]" class="row-wide" :alt="filterProject[0].title" />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>

			</div>
			<!-- End Container -->
			<div v-if="filterProject[0].type === 17 || filterProject[0].type === 21 || filterProject[0].type === 24" class="container-full"> 

				<div class="row justify-content-center">
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="projects" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[0]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[0]" class="row-wide" :alt="filterProject[0].title" />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>
							<!-- End Work -->

						</div>

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title" />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>

			</div>
			<!-- End Container -->
			<div v-if="filterProject[0].type === 19" class="container-full">

				<div class="row justify-content-center">
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-3@m uk-child-width-1-3@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[0]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[0]" class="row-wide" :alt="filterProject[0].title" />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>
							<!-- End Work -->

						</div>

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>
							<!-- End Work -->

						</div>

					</div>

				</div>

			</div>

			<!-- Container Type 2-->
			<div v-if="filterProject[0].type === 23" class="container-full">

				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[0]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[0]" class="row-wide" :alt="filterProject[0].title" />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>

				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title" />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title" />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!------------------ABOUT------------------------------------------------->

		<!-- Project Section -->
		<div data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<div class="container">

				<!-- Row -->
				<div class="row justify-content-center" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

					<!-- Column -->
					<div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

						<h2 class="text-weight-100 text-gray-extra-dark margin-bottom-35px" v-html="filterProject[0].details.about"></h2>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!------------------------------------------------------------------->

		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 1 || filterProject[0].type === 10" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-3@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>
							<!-- End Work -->

						</div>

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 3-->
		<div v-if="filterProject[0].type === 3" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-3@m uk-child-width-1-3@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>
							<!-- End Work -->

						</div>

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!-- Project Section Type 2-->
		<div v-if="filterProject[0].type === 2" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!-- Project Section Type 4-->
		<div v-if="filterProject[0].type === 4" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!-- Project Section Type 5-->
		<div v-if="filterProject[0].type === 5" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="works uk-child-width-1-1@s uk-child-width-1-3@m uk-child-width-1-3@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

							<!-- Work -->
							<div class="images" data-uk-lightbox="animation: fade">

								<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

									<!-- Image -->
									<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

								</a>

							</div>
							<!-- End Work -->

							<!-- Work -->
							<div class="images" data-uk-lightbox="animation: fade">

								<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

									<!-- Image -->
									<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

								</a>
								<!-- End Work -->

							</div>

							<!-- Work -->
							<div class="images" data-uk-lightbox="animation: fade">

								<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

									<!-- Image -->
									<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

								</a>

							</div>
							<!-- End Work -->

						</div>

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

							<!-- Work -->
							<div class="images" data-uk-lightbox="animation: fade">

								<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

									<!-- Image -->
									<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

								</a>

							</div>
							<!-- End Work -->

							<!-- Work -->
							<div class="images" data-uk-lightbox="animation: fade">

								<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

									<!-- Image -->
									<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

								</a>
								<!-- End Work -->

							</div>

						</div>

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!-- Project Section Type 4-->
		<div v-if="filterProject[0].type === 6" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>
							<!-- End Work -->

						</div>

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!-- Project Section Type 7-->
		<div v-if="filterProject[0].type === 7" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!-- Project Section Type 8-->
		<div v-if="filterProject[0].type === 8" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!-- Project Section Type 9-->
		<div v-if="filterProject[0].type === 9" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-1@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 8-->
		<div v-if="filterProject[0].type === 11" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 12" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 21 || filterProject[0].type === 24" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-2@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 14" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-3@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 15" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-2@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 2-->
		<div v-if="filterProject[0].type === 16" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-3@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[1]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 4-->
		<div v-if="filterProject[0].type === 17" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 18-->
		<div v-if="filterProject[0].type === 18" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[2]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[2]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 18-->
		<div v-if="filterProject[0].type === 19" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 18-->
		<div v-if="filterProject[0].type === 23" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!------------------------------------------------------------------->

		<!-- Project Section Type 2-->
		<div v-if="filterProject[0].type === 3" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>
							<!-- End Work -->

						</div>

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!------------------------------------------------------------------->

		<!-- Project Section -->
		<div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-bottom-150px">

			<!-- Container -->
			<div class="container">

				<!-- Row -->
				<div class="row" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

					<!-- Column -->
					<div class="col-md-3 col-sm-12 col-xs-12 center-col text-center margin-auto sm-margin-bottom-50px">

						<h6 class="text-weight-600 text-extra-large text-gray-extra-dark no-margin-bottom">Projecto</h6>

						<!-- Separator -->
						<div class="separator width-15 center-col bottom-border border-1px border-color-gray-dark margin-top-bottom-30px"></div>

						<p class="text-extra-large-3 text-weight-300 text-gray-extra-dark no-margin-bottom barlow-condensed">{{ filterProject[0].details.project }}</p>

					</div>
					<!-- End Column -->

					<!-- Column -->
					<div class="col-md-3 col-sm-12 col-xs-12 center-col text-center margin-auto sm-margin-bottom-50px">

						<h6 class="text-weight-600 text-extra-large text-gray-extra-dark no-margin-bottom">Cliente</h6>

						<!-- Separator -->
						<div class="separator width-15 center-col bottom-border border-1px border-color-gray-dark margin-top-bottom-30px"></div>

						<p class="text-extra-large-3 text-weight-300 text-gray-extra-dark no-margin-bottom barlow-condensed">{{ filterProject[0].details.client }}</p>

					</div>
					<!-- End Column -->

					<!-- Column -->
					<div class="col-md-3 col-sm-12 col-xs-12 center-col text-center margin-auto sm-margin-bottom-50px">

						<h6 class="text-weight-600 text-extra-large text-gray-extra-dark no-margin-bottom">Data</h6>

						<!-- Separator -->
						<div class="separator width-15 center-col bottom-border border-1px border-color-gray-dark margin-top-bottom-30px"></div>

						<p class="text-extra-large-3 text-weight-300 text-gray-extra-dark no-margin-bottom barlow-condensed">{{ filterProject[0].details.date }}</p>

					</div>
					<!-- End Column -->

					<!-- Column -->
					<div class="col-md-3 col-sm-12 col-xs-12 center-col text-center margin-auto">

						<h6 class="text-weight-600 text-extra-large text-gray-extra-dark no-margin-bottom">País</h6>

						<!-- Separator -->
						<div class="separator width-15 center-col bottom-border border-1px border-color-gray-dark margin-top-bottom-30px"></div>

						<p class="text-extra-large-3 text-weight-300 text-gray-extra-dark no-margin-bottom barlow-condensed">{{filterProject[0].details.country}}</p>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!------------------------------------------------------------------->

		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 1" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 2" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 3" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 4-->
		<div v-if="filterProject[0].type === 4" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 5-->
		<div v-if="filterProject[0].type === 5" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[8]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[8]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[9]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[9]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 6-->
		<div v-if="filterProject[0].type === 6" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-1@m uk-child-width-1-1@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 7-->
		<div v-if="filterProject[0].type === 7" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-3@m uk-child-width-1-3@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 5-->
		<div v-if="filterProject[0].type === 8" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 5-->
		<div v-if="filterProject[0].type === 9" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-1@m uk-child-width-1-1@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 10" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse padding-bottom-150px" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 11" data-uk-scrollspy="cls:uk-animation-fade">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse padding-bottom-150px" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<div v-if="filterProject[0].type === 12" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 13" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-2@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[3]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[3]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 14" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-2@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 15" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-3@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[8]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[8]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 16" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 7-->
		<div v-if="filterProject[0].type === 17" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-3@m uk-child-width-1-3@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 18" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[8]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[8]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[9]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[9]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 19" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[8]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[8]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 20 || filterProject[0].type === 22" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[4]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[4]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->
				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 21" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 23" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 24" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[5]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[5]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!------------------------------------------------------------------->

		<!-- Project Section -->
		<div data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-0px">

			<div class="container">

				<!-- Row -->
				<div class="row" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

					<!-- Column -->
					<div class="col-md-12 col-sm-12 col-xs-12 center-col text-center margin-bottom-100px">

						<h5 class="text-weight-500 text-gray-extra-dark">{{ filterProject[0].details.slogan }}</h5>

						<div class="width-100 sm-width-100">

							<p class="text-gray-dark" v-html="filterProject[0].details.desc"></p>

						</div>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<!------------------------------------------------------------------->

		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 2" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[8]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[8]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[9]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[9]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 4" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 7" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[6]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[6]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[8]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[8]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 10-->
		<div v-if="filterProject[0].type === 14" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-2@s uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[7]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[7]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[8]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[8]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 15" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[9]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[9]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 18" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[10]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[10]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[11]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[11]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 19" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[9]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[9]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->
						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[10]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[10]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[11]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[11]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->
		<!-- Project Section Type 1-->
		<div v-if="filterProject[0].type === 23" data-uk-scrollspy="cls:uk-animation-fade" class="padding-bottom-150px">

			<!-- Container -->
			<div class="container-full">

				<!-- Row -->
				<div class="row justify-content-center">

					<!-- Work -->
					<div class="works uk-child-width-1-1@s row-wide uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div class="images" data-uk-lightbox="animation: fade">

							<a :href="'../images/works/'+filterProject[0].details.images[8]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

								<!-- Image -->
								<img :src="'../images/works/'+filterProject[0].details.images[8]" class="row-wide" :alt="filterProject[0].title"  />

							</a>

						</div>
						<!-- End Work -->

					</div>
				
				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</div>
		<!-- End Project Section -->

		<div class="pager text-center">
			<a :href="prevProject()" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px"> PROJETO Anterior</a>
			<a :href="nextProject()" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px">PROJETO Seguinte</a>
			<!-- <router-link :to="prevProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Anterior</router-link>
			<router-link :to="nextProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Próximo</router-link> -->
		</div>
		<footer-main-component></footer-main-component>

    </div>
</template>

<script>
import FooterMainComponent from './FooterMainComponent.vue'
import { allProjects } from '../projects'

export default {
  name: 'Details',
  props: ['slug'],
  components: {
    FooterMainComponent
  },
  data: function () {
    return {
      projects: allProjects
    }
  },
  methods: {
    getProject: function (projects) {
      return this.projects.filter(item => !item.slug.toLowerCase().indexOf(this.slug))
    },
    nextProject: function (projects) {
    	if (this.filterProject[0].id + 1 >= this.projects.length) {
    		return this.projects[0].slug
      } else {
        return this.projects[this.filterProject[0].id + 1].slug
      }
    },
    prevProject: function (projects) {
    	if (this.filterProject[0].id - 1 <= 0) {
        return this.projects[this.projects.length - 1].slug
      } else {
        return this.projects[this.filterProject[0].id - 1].slug
      	}
    }
  },
  computed: {
    filterProject: function () {
      return this.getProject(this.projects)
    }
  },
  metaInfo () {
    return {
	 title: this.filterProject[0].meta.title,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.filterProject[0].meta.desc
      }]
    }
  }
}
</script>
