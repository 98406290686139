<template>
<!-- Container Type 1-->
    <div data-uk-scrollspy="cls:uk-animation-fade">
        <div class="container-full">

            <div class="row justify-content-center">

                <!-- Work -->
                <div class="works uk-child-width-1-1@s row-wide uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">

                    <!-- Work -->
                    <div class="projects" data-uk-lightbox="animation: fade">

                        <a :href="'../images/works/'+images[0]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

                            <!-- Image -->
                            <img :src="'../images/works/'+images[0]" class="row-wide" alt="" />

                        </a>

                    </div>
                    <!-- End Work -->

                    <!-- Work -->
                    <div class="images" data-uk-lightbox="animation: fade">

                        <a :href="'../images/works/'+images[1]" class="row-wide uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

                            <!-- Image -->
                            <img :src="'../images/works/'+images[1]" class="row-wide" alt=""  />

                        </a>
                        <!-- End Work -->

                    </div>

                </div>

            </div>

        </div>
    </div>
    <!-- End Container -->
</template>

<script>

export default {
  props: ['images'],
  data () {
    return {
      
    }
  },
  created () {
  },
  
}
</script>