<template>
    <div class="details">
        <!-- Project Section -->
        <images2 :images="['piriquita/piriquita_2.jpg', 'piriquita/piriquita_3.jpg']"></images2>
        <!------------------ABOUT------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-150px padding-bottom-150px">

            <div class="container">

            <!-- Row -->
            <div class="row justify-content-center" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

                <!-- Column -->
                <div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

                <h2 class="text-weight-100 text-gray-extra-dark margin-bottom-35px"><strong>Redesign da marca</strong> Piriquita.</h2>

                </div>
                <!-- End Column -->

            </div>
            <!-- End Row -->

            </div>
            <!-- End Container -->

        </div>
        <!------------------ABOUT------------------------------------------------->
        <full-image :images="['piriquita/piriquita_1.jpg']"></full-image>
        <images2 :images="['piriquita/piriquita_4.jpg', 'piriquita/piriquita_5.jpg']"></images2>
        <project-details project="Rebranding, Design gráfico e de Embalagem" client="" date="2015" country="Portugal"></project-details>
        <images2 :images="['piriquita/piriquita_6.jpg', 'piriquita/piriquita_7.jpg']"></images2>
        <!------------------DESC------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="margin-bottom-150px margin-top-150px">
            <div class="container">
                <div class="row" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">
                    <div class="col-md-12 col-sm-12 col-xs-12 center-col text-center">
                        <h5 class="text-weight-500 text-gray-extra-dark">Tradição com um toque de modernidade</h5>
                        <div class="width-100 sm-width-100">
                            <p class="text-gray-dark">Para o rebranding da marca Piriquita redesenhámos o logotipo existente, preservando o conceito, mas atualizando a linguagem gráfica, tornando-o mais simples e assim mais facilmente apreendido. Através da simplicidade fortalecemos a imagem da marca. O azul permitiu igualmente manter ligação ao passado.</p>
                        </div>
                    </div>
                </div>
            </div>
	    </div>
        <full-image :images="['piriquita/piriquita_8.jpg']"></full-image>
        <images2 :images="['piriquita/piriquita_9.png', 'piriquita/piriquita_10.jpg']"></images2>
        <!------------------DESC------------------------------------------------->
        <div class="pager text-center margin-top-150px">
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px"> PROJETO Anterior</a>
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px">PROJETO Seguinte</a>
            <!-- <router-link :to="prevProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Anterior</router-link>
            <router-link :to="nextProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Próximo</router-link> -->
        </div>
      
        <footer-main-component></footer-main-component>
    </div>
</template>

<script>
import FooterMainComponent from './../FooterMainComponent.vue'
import Images2 from './../projects/types/2images.vue'
import Images3 from './../projects/types/3images.vue'
import ProjectDetails from './../projects/types/details.vue'
import FullImage from './../projects/types/fullimage.vue'


export default {
  name: 'Details',
  components: {
    FooterMainComponent,
    Images2,
    Images3,
    ProjectDetails,
    FullImage
  },
  metaInfo () {
    return {
	 title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Redesign da marca Piriquita',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos para a Piriquita no redesign da marca. Rebranding, Design gráfico e de Embalagem.'
      }]
    }
  }
}
</script>
