<template>
    <div>
        <!-- Left Section -->
        <div class="left-side image-bg-home">

            <!-- Overlay Content -->
            <div class="uk-position-center text-center sm-text-center">

                <div class="overlay-white padding-top-bottom-50px md-padding-50px xs-width-100 width-800">
                    <h5 class="text-extra-large text-black text-weight-500 text-uppercase margin-top-15px">Especialistas em identidades visuais </h5>
                    <!-- Heading -->
                    <h2 class="text-weight-700 text-black width-100 line-height-regular margin-auto">CRIAMOS O SEU LOGOTIPO</h2>

                </div>

            </div>
            <!-- Overlay Content -->

        </div>
        <!-- End Left Side -->
        
        <!-- Start Right Side -->
        <div class="right-side">

            <!-- Portfolio -->
            <section>

                <!-- Container -->
                <div class="container-full">

                    <!-- Portfolio Grid -->
                    <div data-uk-filter="target: .works">

                        <!-- Work -->
                        <div class="works uk-child-width-1-1@s uk-child-width-1-1@m uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div > a; delay: 50; repeat: false">
                            
                            <!-- Work -->
                            <div class="projects">

                                <a href="/portfolio/cal" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="Clube dos Amigos do Livro">
                                <!-- <router-link :to="{ name: 'Details', params:{slug: 'cal'} }" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="Clube dos Amigos do Livro"> -->

                                    <!-- Image -->
                                    <img src="images/works/cal.jpg" alt="" />

                                    <!-- Hover -->
                                    <div class="uk-transition-slide-bottom uk-position-bottom bg-white height-40">

                                        <div class="uk-position-center-left text-left margin-left-50px">

                                            <h3 class="margin-bottom-5px text-weight-500 text-gray-extra-dark">Cal</h3>

                                            <p class="no-margin-bottom text-weight-400 text-gray-regular">Clube dos Amigos do Livro</p>

                                            <div class="separator width-100 bottom-border border-1px border-color-gray-extra-light margin-top-bottom-15px"></div>

                                            <span data-uk-icon="icon: image; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="image"> <circle cx="16.1" cy="6.1" r="1.1"></circle> <rect fill="none" stroke="#000" x="0.5" y="2.5" width="19" height="15"></rect> <polyline fill="none" stroke="#000" stroke-width="1.01" points="4,13 8,9 13,14"></polyline> <polyline fill="none" stroke="#000" stroke-width="1.01" points="11,12 12.5,10.5 16,14"></polyline></svg></span>
                                            <span data-uk-icon="icon: link; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"> <path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>

                                        </div>

                                    </div>
                                    <!-- End Hover -->

                                </a>

                            </div>
                            <!-- End Work -->

                            <!-- Work -->
                            <div class="projects">

                                <a href="/portfolio/piriquita" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="Piriquita">
                                <!-- <router-link :to="{ name: 'Details', params:{slug: 'piriquita'} }" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="Piriquita"> -->

                                    <!-- Image -->
                                    <img src="images/works/piriquita.jpg" alt="" />

                                    <!-- Hover -->
                                    <div class="uk-transition-slide-bottom uk-position-bottom bg-white height-40">

                                        <div class="uk-position-center-left text-left margin-left-50px">

                                            <h3 class="margin-bottom-5px text-weight-500 text-gray-extra-dark">Piriquita</h3>

                                            <p class="no-margin-bottom text-weight-400 text-gray-regular">Antiga Fábrica de Queijadas Finas</p>

                                            <div class="separator width-100 bottom-border border-1px border-color-gray-extra-light margin-top-bottom-15px"></div>

                                            <span data-uk-icon="icon: image; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="image"> <circle cx="16.1" cy="6.1" r="1.1"></circle> <rect fill="none" stroke="#000" x="0.5" y="2.5" width="19" height="15"></rect> <polyline fill="none" stroke="#000" stroke-width="1.01" points="4,13 8,9 13,14"></polyline> <polyline fill="none" stroke="#000" stroke-width="1.01" points="11,12 12.5,10.5 16,14"></polyline></svg></span>
                                            <span data-uk-icon="icon: link; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"> <path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>

                                        </div>

                                    </div>
                                    <!-- End Hover -->

                                </a>
                                <!-- End Work -->

                            </div>

                            <!-- Work -->
                            <div class="projects">

                                <a href="/portfolio/marcha-da-mouraria" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="marcha da mouraria">
                                <!-- <router-link :to="{ name: 'Details', params:{slug: 'marcha-da-mouraria'} }" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="marcha da mouraria"> -->

                                    <!-- Image -->
                                    <img src="images/works/marchamouraria.jpg" alt="" />

                                    <!-- Hover -->
                                    <div class="uk-transition-slide-bottom uk-position-bottom bg-white height-40">

                                        <div class="uk-position-center-left text-left margin-left-50px">

                                            <h3 class="margin-bottom-5px text-weight-500 text-gray-extra-dark">Marcha da Mouraria</h3>

                                            <p class="no-margin-bottom text-weight-400 text-gray-regular">Marcha Lisboeta</p>

                                            <div class="separator width-100 bottom-border border-1px border-color-gray-extra-light margin-top-bottom-15px"></div>

                                            <span data-uk-icon="icon: image; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="image"> <circle cx="16.1" cy="6.1" r="1.1"></circle> <rect fill="none" stroke="#000" x="0.5" y="2.5" width="19" height="15"></rect> <polyline fill="none" stroke="#000" stroke-width="1.01" points="4,13 8,9 13,14"></polyline> <polyline fill="none" stroke="#000" stroke-width="1.01" points="11,12 12.5,10.5 16,14"></polyline></svg></span>
                                            <span data-uk-icon="icon: link; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"> <path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>

                                        </div>

                                    </div>
                                    <!-- End Hover -->

                                </a>

                            </div>
                            <!-- End Work -->

                            <!-- Work -->
                            <div class="projects">

                                <a href="/portfolio/green-pine" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="">

                                    <!-- Image -->
                                    <img src="images/works/GreenPine.jpg" alt="" />

                                    <!-- Hover -->
                                    <div class="uk-transition-slide-bottom uk-position-bottom bg-white height-40">

                                        <div class="uk-position-center-left text-left margin-left-50px">

                                            <h3 class="margin-bottom-5px text-weight-500 text-gray-extra-dark">Green Pine Coffee & Store</h3>

                                            <p class="no-margin-bottom text-weight-400 text-gray-regular">Espaço de lazer e comercial</p>

                                            <div class="separator width-100 bottom-border border-1px border-color-gray-extra-light margin-top-bottom-15px"></div>

                                            <span data-uk-icon="icon: image; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="image"> <circle cx="16.1" cy="6.1" r="1.1"></circle> <rect fill="none" stroke="#000" x="0.5" y="2.5" width="19" height="15"></rect> <polyline fill="none" stroke="#000" stroke-width="1.01" points="4,13 8,9 13,14"></polyline> <polyline fill="none" stroke="#000" stroke-width="1.01" points="11,12 12.5,10.5 16,14"></polyline></svg></span>
                                            <span data-uk-icon="icon: link; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"> <path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>

                                        </div>

                                    </div>
                                    <!-- End Hover -->

                                </a>

                            </div>
                            <!-- End Work -->

                            <!-- Work -->
                            <div class="projects">

                                <a href="/portfolio/booksfactory" class="uk-inline-clip uk-transition-toggle" tabindex="0">
                                <!-- <router-link :to="{name: 'Details', params:{slug: 'booksfactory'}}" class="uk-inline-clip uk-transition-toggle" tabindex="0"> -->

                                    <!-- Image -->
                                    <img src="images/works/booksfactory.jpg" alt="" />

                                    <!-- Hover -->
                                    <div class="uk-transition-slide-bottom uk-position-bottom bg-white height-40">

                                        <div class="uk-position-center-left text-left margin-left-50px">

                                            <h3 class="margin-bottom-5px text-weight-500 text-gray-extra-dark">BooksFactory</h3>

                                            <p class="no-margin-bottom text-weight-400 text-gray-regular">Editora</p>

                                            <div class="separator width-100 bottom-border border-1px border-color-gray-extra-light margin-top-bottom-15px"></div>

                                            <span data-uk-icon="icon: image; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="image"> <circle cx="16.1" cy="6.1" r="1.1"></circle> <rect fill="none" stroke="#000" x="0.5" y="2.5" width="19" height="15"></rect> <polyline fill="none" stroke="#000" stroke-width="1.01" points="4,13 8,9 13,14"></polyline> <polyline fill="none" stroke="#000" stroke-width="1.01" points="11,12 12.5,10.5 16,14"></polyline></svg></span>
                                            <span data-uk-icon="icon: link; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"> <path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>

                                        </div>

                                    </div>
                                    <!-- End Hover -->

                                </a>
                                <!-- End Work -->

                            </div>

                            <!-- Work -->
                            <div class="projects">

                                <a href="/portfolio/instalmed" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="Instalmed">
                                <!-- <router-link :to="{ name: 'Details', params:{slug: 'instalmed'} }" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="Instalmed"> -->

                                    <!-- Image -->
                                    <img src="images/works/instalmed.jpg" alt="" />

                                    <!-- Hover -->
                                    <div class="uk-transition-slide-bottom uk-position-bottom bg-white height-40">

                                        <div class="uk-position-center-left text-left margin-left-50px">

                                            <h3 class="margin-bottom-5px text-weight-500 text-gray-extra-dark">Instalmed</h3>

                                            <p class="no-margin-bottom text-weight-400 text-gray-regular">Equipamento médico</p>

                                            <div class="separator width-100 bottom-border border-1px border-color-gray-extra-light margin-top-bottom-15px"></div>

                                            <span data-uk-icon="icon: image; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="image"> <circle cx="16.1" cy="6.1" r="1.1"></circle> <rect fill="none" stroke="#000" x="0.5" y="2.5" width="19" height="15"></rect> <polyline fill="none" stroke="#000" stroke-width="1.01" points="4,13 8,9 13,14"></polyline> <polyline fill="none" stroke="#000" stroke-width="1.01" points="11,12 12.5,10.5 16,14"></polyline></svg></span>
                                            <span data-uk-icon="icon: link; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"> <path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>

                                        </div>

                                    </div>

                                </a>
                                <!-- End Hover -->

                            </div>

                            <!-- Work -->
                            <div class="projects">

                                <a href="/portfolio/junkembo" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="Junkembo">
                                <!-- <router-link :to="{ name: 'Details', params:{slug: 'junkembo'} }" class="uk-inline-clip uk-transition-toggle" tabindex="0" data-caption="Junkembo"> -->

                                    <!-- Image -->
                                    <img src="images/works/junkembo.jpg" alt="" />

                                    <!-- Hover -->
                                    <div class="uk-transition-slide-bottom uk-position-bottom bg-white height-40">

                                        <div class="uk-position-center-left text-left margin-left-50px">

                                            <h3 class="margin-bottom-5px text-weight-500 text-gray-extra-dark">Junkembo</h3>

                                            <p class="no-margin-bottom text-weight-400 text-gray-regular">Restaurante - bar</p>

                                            <div class="separator width-100 bottom-border border-1px border-color-gray-extra-light margin-top-bottom-15px"></div>

                                            <span data-uk-icon="icon: image; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="image"> <circle cx="16.1" cy="6.1" r="1.1"></circle> <rect fill="none" stroke="#000" x="0.5" y="2.5" width="19" height="15"></rect> <polyline fill="none" stroke="#000" stroke-width="1.01" points="4,13 8,9 13,14"></polyline> <polyline fill="none" stroke="#000" stroke-width="1.01" points="11,12 12.5,10.5 16,14"></polyline></svg></span>
                                            <span data-uk-icon="icon: link; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"> <path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>

                                        </div>

                                    </div>

                                </a>
                                <!-- End Hover -->

                            </div>

                            <!-- Work -->
                            <div class="projects">

                                <a href="/portfolio/capid" class="projects uk-inline-clip uk-transition-toggle" tabindex="0">

                                    <!-- Image -->
                                    <img src="images/works/capid.jpg" alt="" />

                                    <!-- Hover -->
                                    <div class="uk-transition-slide-bottom uk-position-bottom bg-white height-40">

                                        <div class="uk-position-center-left text-left margin-left-50px">

                                            <h3 class="margin-bottom-5px text-weight-500 text-gray-extra-dark">Capid</h3>

                                            <p class="no-margin-bottom text-weight-400 text-gray-regular">Gestão de activos e investimentos</p>

                                            <div class="separator width-100 bottom-border border-1px border-color-gray-extra-light margin-top-bottom-15px"></div>

                                            <span data-uk-icon="icon: image; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="image"> <circle cx="16.1" cy="6.1" r="1.1"></circle> <rect fill="none" stroke="#000" x="0.5" y="2.5" width="19" height="15"></rect> <polyline fill="none" stroke="#000" stroke-width="1.01" points="4,13 8,9 13,14"></polyline> <polyline fill="none" stroke="#000" stroke-width="1.01" points="11,12 12.5,10.5 16,14"></polyline></svg></span>
                                            <span data-uk-icon="icon: link; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"> <path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>

                                        </div>

                                    </div>

                                </a>
                                <!-- End Hover -->

                            </div>

                            <!-- Work -->
                            <div class="projects">

                                <a href="/portfolio/honest-turtle" class="projects uk-inline-clip uk-transition-toggle" tabindex="0">

                                    <!-- Image -->
                                    <img src="images/works/HonestTurtle.jpg" alt="" />

                                    <!-- Hover -->
                                    <div class="uk-transition-slide-bottom uk-position-bottom bg-white height-40">

                                        <div class="uk-position-center-left text-left margin-left-50px">

                                            <h3 class="margin-bottom-5px text-weight-500 text-gray-extra-dark">Honest Turtle</h3>

                                            <p class="no-margin-bottom text-weight-400 text-gray-regular">Audiovisuais</p>

                                            <div class="separator width-100 bottom-border border-1px border-color-gray-extra-light margin-top-bottom-15px"></div>

                                            <span data-uk-icon="icon: image; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="image"> <circle cx="16.1" cy="6.1" r="1.1"></circle> <rect fill="none" stroke="#000" x="0.5" y="2.5" width="19" height="15"></rect> <polyline fill="none" stroke="#000" stroke-width="1.01" points="4,13 8,9 13,14"></polyline> <polyline fill="none" stroke="#000" stroke-width="1.01" points="11,12 12.5,10.5 16,14"></polyline></svg></span>
                                            <span data-uk-icon="icon: link; ratio: 0.8" class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px uk-icon"><svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="link"> <path fill="none" stroke="#000" stroke-width="1.1" d="M10.625,12.375 L7.525,15.475 C6.825,16.175 5.925,16.175 5.225,15.475 L4.525,14.775 C3.825,14.074 3.825,13.175 4.525,12.475 L7.625,9.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M9.325,7.375 L12.425,4.275 C13.125,3.575 14.025,3.575 14.724,4.275 L15.425,4.975 C16.125,5.675 16.125,6.575 15.425,7.275 L12.325,10.375"></path> <path fill="none" stroke="#000" stroke-width="1.1" d="M7.925,11.875 L11.925,7.975"></path></svg></span>

                                        </div>

                                    </div>

                                </a>
                                <!-- End Hover -->

                            </div>

                        </div>

                    </div>

                </div>
                <!-- End Container -->

            </section>
            <!-- End Portfolio -->

            <!-- About -->
            <div class="padding-top-bottom-150px md-padding-top-bottom-70px">

                <!-- Row -->
                <div class="row" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

                    <!-- Column -->
                    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 center-col text-left margin-auto sm-text-center sm-width-90">

                        <!-- Heading -->
                        <h3 class="text-weight-600 text-black">A MYLOGO</h3>

                        <!-- Separator -->
                        <div class="separator width-30px bottom-border border-2px border-color-gray-light margin-top-bottom-40px md-margin-left-right-auto"></div>

                        <!-- Title -->
                        <h5 class="roboto text-extra-large text-weight-300 text-black">Somos especialistas em identidades visuais.<br>Temos <strong>uma equipa dedicada a si e ao seu projeto.</strong><br>Prometemos criatividade, qualidade, prontidão e total empenho.<br>Do que precisa mais?</h5>

                        <a href="/mylogo" class="btn border-radius-50 btn-small btn-black sm-margin-left-right-auto sm-display-table xs-no-margin-bottom">Conheça-nos</a>
                        <!-- <router-link :to="{ name: 'Mylogo' }" class="btn border-radius-50 btn-small btn-black sm-margin-left-right-auto sm-display-table xs-no-margin-bottom">Conheça-nos</router-link> -->
                    </div>
                    <!-- End Column -->

                </div>
                <!-- End Row -->

            </div>
            <!-- End About -->

            <!-- Services -->
            <div class="padding-top-bottom-150px md-padding-top-bottom-70px bg-gray-extra-light-2">

                <!-- Row -->
                <div class="row">

                    <!-- Column -->
                    <div class="col-md-8 col-sm-12 col-xs-12 center-col xs-text-center text-left margin-auto">

                        <!-- Title -->
                        <div class="position-relative overflow-hidden page-title-smaller margin-auto">

                            <!-- Heading -->
                            <h3 class="text-weight-600 text-white">SERVIÇOS</h3>
                            <!-- Separator -->
                            <div class="separator width-30px bottom-border border-2px border-color-gray-light margin-top-bottom-40px md-margin-left-right-auto"></div>
                            
                        </div>
                        <!-- End Title -->

                    </div>
                    <!-- End Column -->

                </div>
                <!-- End Row -->

                <!-- Container -->
                <div class="row">

                    <!-- Animation -->
                    <div class="col-md-8 col-sm-12 col-xs-12 center-col margin-auto" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > .row > div; delay: 50; repeat: false">

                        <!-- Row -->
                        <div class="row">

                            <div class="col-md-12 col-sm-12 col-xs-12 padding-top-bottom-20px text-left xs-text-center xs-no-border-right">

                                <p class="no-margin-bottom text-weight-600 text-extra-large-5 text-white barlow-condensed">Identidade visual</p>

                            </div>

                            <div class="col-md-12 col-sm-12 col-xs-12 padding-top-bottom-10px text-left xs-text-center xs-no-border-right">

                                <p class="no-margin-bottom text-weight-400 text-extra-large-3 text-white barlow-condensed">Criação de logotipo</p>

                            </div>

                            <div class="col-md-12 col-sm-12 col-xs-12 padding-top-bottom-10px text-left xs-text-center xs-no-border-right">

                                <p class="no-margin-bottom text-weight-400 text-extra-large-3 text-white barlow-condensed">Manual de identidade visual</p>

                            </div>

                            <div class="col-md-12 col-sm-12 col-xs-12 padding-top-bottom-10px text-left xs-text-center xs-no-border-right">

                                <p class="no-margin-bottom text-weight-400 text-extra-large-3 text-white barlow-condensed">Design gráfico</p>

                            </div>

                             <div class="col-md-12 col-sm-12 col-xs-12 padding-top-bottom-20px text-left xs-text-center xs-no-border-right">

                                <a href="/servicos" class="btn border-radius-50 btn-small btn-white sm-margin-left-right-auto sm-display-table xs-no-margin-bottom">Saiba mais</a>
                            
                            </div>

                    </div>
                    <!-- End Row -->

                    </div>
                    <!-- End Animation -->

                </div>
                <!-- End Container -->

            </div>
            <!-- End Services Style 03 -->

            <section class="padding-top-bottom-150px md-padding-top-bottom-70px bg-gray-extra-light">

                <!-- Container -->
                <div class="container">

                    <!-- Row -->
                    <div class="row">

                        <!-- Column -->
                        <div class="col-md-7 col-sm-12 col-xs-12 center-col text-center margin-auto">

                            <!-- Modal Trigger Container -->
                            <div data-uk-scrollspy="cls: uk-animation-slide-bottom; target: div; delay: 50; repeat: false">

                                <!-- Text -->
                                <div style="" class="uk-scrollspy-inview uk-animation-slide-bottom">

                                    <h5 class="text-weight-600 text-black margin-bottom-35px">Vai abrir uma empresa?<br> Vai criar o seu negócio?<br> Estamos aqui.</h5>

                                </div>
                                <!-- End Text -->

                                <!-- Button -->
                                <div style="" class="uk-scrollspy-inview uk-animation-slide-bottom">

                                    <a href="/contactos" class="btn border-radius-50 btn-small btn-black md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Contacte-nos</a>

                                </div>
                                <!-- End Button -->

                            </div>
                            <!-- End Modal Trigger Container -->

                            <!-- Modal Pop Up -->
                            <contacto-pop-up-component></contacto-pop-up-component>
                            <!-- End Modal Pop Up -->

                        </div>
                        <!-- End Column -->

                    </div>
                    <!-- End Row -->

                </div>
                <!-- End Container -->

            </section>

            <footer-component></footer-component>
        </div>
    </div>
</template>

<script>
import FooterComponent from './FooterComponent.vue'
import ContactoPopUpComponent from './ContactoPopUpComponent.vue'

export default {
  name: 'Home',
  components: {
    FooterComponent,
    ContactoPopUpComponent
  },
  metaInfo: {
    title: 'SITE MYLOGO. Especialistas em identidades visuais',
    meta: [
      { charset: 'utf-8' },
      { vmid: 'description', name: 'description', content: 'SITE MYLOGO. Especialistas em identidades visuais. Serviços. Criação de marca, naming, logotipo, estacionário,divulgação institucional, publicidade, packaging, websites, gestão redes sociais, anunciar na web' }
    ]
  }
}
</script>
