<template>
	<div id="app">
		<header-component></header-component>
		<router-view></router-view>
	</div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
	name: 'App',
	components: {
    	HeaderComponent,
    	FooterComponent
  	}
}
</script>

<style lang="scss">

</style>
