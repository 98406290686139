<template>
    <div class="details">
        <!-- Project Section -->
        <images2 :images="['booksfactory/booksfactory_1.jpg', 'booksfactory/booksfactory_2.jpg']"></images2>
         <!------------------ABOUT------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-150px padding-bottom-150px">

            <div class="container">

            <!-- Row -->
            <div class="row justify-content-center" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

                <!-- Column -->
                <div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

                <h2 class="text-weight-100 text-gray-extra-dark margin-bottom-35px"><strong>Design da marca</strong> para a editora Booksfactory</h2>

                </div>
                <!-- End Column -->

            </div>
            <!-- End Row -->

            </div>
            <!-- End Container -->

        </div>
        <!------------------ABOUT------------------------------------------------->
        <full-image :images="['booksfactory/booksfactory_3.jpg']"></full-image>
         <!------------------ABOUT------------------------------------------------->
        <project-details project="Design de marca, Design interiores" client="Letras Impares" date="2015" country="Portugal"></project-details>
        <images3 :images="['booksfactory/booksfactory_4.jpg', 'booksfactory/booksfactory_5.jpg', 'booksfactory/booksfactory_6.jpg']"></images3>
        <!------------------DESC------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="margin-bottom-150px margin-top-150px">
            <div class="container">
                <div class="row" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">
                    <div class="col-md-12 col-sm-12 col-xs-12 center-col text-center">
                        <h5 class="text-weight-500 text-gray-extra-dark">A fábrica dos livros</h5>
                        <div class="width-100 sm-width-100">
                            <p class="text-gray-dark">Ao conceber o logotipo para a BooksFactory, procurámos buscou uma abordagem única que unisse os elementos característicos da indústria editorial com a idea de produção criativa. O design desenvolvido representa a fusão de um livro e uma fábrica, criando uma metáfora visual que sugere a idea de criação, produção e fluxo de conhecimento.<br>
                            A opção por formas simples e curvilíneas no logotipo da BooksFactory foi uma escolha consciente. Essas formas transmitem uma sensação de acessibilidade e aconchego, elementos que são essenciais no mundo da leitura e da literatura. A simplicidade das formas também facilita a rápida assimilação do logotipo, tornando-o mais facilmente reconhecível mesmo em tamanhos reduzidos.<br>
                            A escolha do vermelho como cor predominant no logotipo da BooksFactory tem um propósito claro: atrair a atenção do consumidor. No entanto o seu tom é escuro, para transmitir igualmente sofisticação e cuidado.</p>
                        </div>
                    </div>
                </div>
            </div>
	    </div>
        <full-image :images="['booksfactory/booksfactory_7.jpg']"></full-image>
        <images2 :images="['booksfactory/booksfactory_8.jpg', 'booksfactory/booksfactory_9.jpg']"></images2>
        <!------------------DESC------------------------------------------------->
        <div class="pager text-center margin-top-150px">
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px"> PROJETO Anterior</a>
            <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px">PROJETO Seguinte</a>
            <!-- <router-link :to="prevProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Anterior</router-link>
            <router-link :to="nextProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Próximo</router-link> -->
        </div>
      
        <footer-main-component></footer-main-component>
    </div>
</template>

<script>
import FooterMainComponent from '../FooterMainComponent.vue'
import Images2 from './types/2images.vue'
import Images3 from './types/3images.vue'
import ProjectDetails from './types/details.vue'
import FullImage from './types/fullimage.vue'


export default {
  name: 'Details',
  components: {
    FooterMainComponent,
    Images2,
    Images3,
    ProjectDetails,
    FullImage
  },
  metaInfo () {
    return {
	 title: 'Portfólio MYLOGO – Design da marca para a editora BooksFactory',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a editora BooksFactory.A fábrica dos livros.'
      }]
    }
  }
}
</script>
