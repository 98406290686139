<template>
    <div>
		<!-- Background Style 02 -->
		<section class="overlay-black image-bg-success" data-uk-height-viewport>

			<!-- Positioning -->
			<div class="uk-position-center width-80 md-width-90">

				<!-- Heading -->
				<h1 class="text-uppercase text-weight-700 text-white">OBRIGADO.<br/> <span class="text-weight-200">NUM INSTANTE ESTAMOS EM CONTACTO.</span></h1>

				<!-- Separator -->
				<div class="separator width-50 bottom-border border-1px border-color-gray-dark margin-top-40px margin-bottom-50px"></div>

				<!-- Row -->
				<div class="text-left margin-top-50px" data-uk-grid>

					<a href="https://www.facebook.com/MYLOGOdesign" target="_blank" class="text-white" data-uk-icon="icon: facebook; ratio: 1.5"></a>

					<a href="https://www.instagram.com/mylogo_design/" target="_blank" class="text-white" data-uk-icon="icon: instagram; ratio: 1.5"></a>

					<a href="#" class="text-white"  target="_blank" data-uk-icon="icon: linkedin; ratio: 1.5"></a>

					<a href="mailto:geral@mylogo.pt" class="text-white" target="_blank" data-uk-icon="icon: mail; ratio: 1.5"></a>

				</div>

			</div>
			<!-- End Positioning -->

		</section>
		<!-- End Background Style 02 -->
    </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
	  window.gtag('event', 'conversion', {'send_to': 'AW-1017159455/XWHTCKr_wngQn76C5QM'})
  },
  
}
</script>
