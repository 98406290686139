var allProjects = [
  {
    id: 0,
    slug: 'cal',
    title: 'CAL',
    subtitle: 'Clube dos Amigos do Livro',
    mainImage: 'cal/cal.jpg',
    type: 1,
    details: {
      images: [
        'cal/cal_1.jpg',
        'cal/cal_2.jpg',
        'cal/cal_3.jpg',
        'cal/cal_4.jpg',
        'cal/cal_5.jpg',
        'cal/cal_6.jpg',
        'cal/cal_7.jpg',
        'cal/cal_8.jpg'
      ],
      about: '<strong>Design da marca</strong> <br>CAL - Clube dos Amigos do Livro.',
      project: 'Design de marca, Design Gráfico',
      client: 'Letras ímpares, Lda',
      date: 'Desde 2020',
      country: 'Portugal',
      slogan: 'Livros e leitores - A aliança perfeita.',
      desc: 'O logotipo do CAL, foi criado com o objetivo de transmitir uma imagem alegre, atrativa e multigeracional. A idea central do simbolo é representar as lombadas dos livros em conjunto com a sigla CAL.<br>A imagem busca destacar o prazer da leitura, abrangendo tanto os leitores mais jovens quanto os mais velhos, estabelecendo um vínculo entre o leitor e a marca que reforça a ideia de um clube. O design foi cuidadosamente desenvolvido para transmitir a sensação de pertença a uma comunidade de amantes da leitura.<br>As cores escolhidas para o logotipo são alegres e vibrantes, trazendo vivacidade à marca.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca CAL - Clube dos Amigos do Livro.',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a CAL - Clube dos Amigos do Livro. Livros e leitores - A aliança perfeita'
    }
  },
  {
    id: 1,
    slug: 'piriquita',
    title: 'Piriquita',
    subtitle: 'Antiga Fábrica de Queijadas Finas',
    mainImage: 'piriquita/piriquita.jpg',
    type: 2,
    details: {
      images: [
        'piriquita/piriquita_2.jpg',
        'piriquita/piriquita_3.jpg',
        'piriquita/piriquita_1.jpg',
        'piriquita/piriquita_4.jpg',
        'piriquita/piriquita_5.jpg',
        'piriquita/piriquita_6.jpg',
        'piriquita/piriquita_7.jpg',
        'piriquita/piriquita_8.jpg',
        'piriquita/piriquita_9.png',
        'piriquita/piriquita_10.jpg'
      ],
      about: '<strong>Redesign da marca</strong> Piriquita.',
      project: 'Rebranding, Design gráfico e de Embalagem',
      client: '',
      date: '2015',
      country: 'Portugal',
      slogan: 'Tradição com um toque de modernidade',
      desc: 'Para o rebranding da marca Piriquita redesenhámos o logotipo existente, preservando o conceito, mas atualizando a linguagem gráfica, tornando-o mais simples e assim mais facilmente apreendido. Através da simplicidade fortalecemos a imagem da marca. O azul permitiu igualmente manter ligação ao passado.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Redesign da marca Piriquita',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos para a Piriquita no redesign da marca. Rebranding, Design gráfico e de Embalagem.'
    }
  },
  {
    id: 2,
    slug: 'marcha-da-mouraria',
    title: 'Marcha da Mouraria',
    subtitle: 'Marcha Lisboeta',
    mainImage: 'marcha/marchamouraria.jpg',
    type: 3,
    details: {
      images: [
        'marcha/marchadamouraria_1B.jpg',
        'marcha/marchadamouraria_2.jpg',
        'marcha/marchadamouraria_3.jpg',
        'marcha/marchadamouraria_4.jpg',
        'marcha/marchadamouraria_5B.jpg',
        'marcha/marchadamouraria_6.jpg',
        'marcha/marchadamouraria_7.jpg',
        'marcha/marchadamouraria_8.jpg'
      ],
      about: '<strong>Design da marca</strong> da marcha da Mouraria',
      project: 'Design de marca, Design gráfico, Publicidade',
      client: 'Grupo Desportivo da Mouraria',
      date: '2019',
      country: 'Portugal',
      slogan: 'O orgulho na marcha',
      desc: 'O logotipo criado para representar a Marcha da Mouraria é uma imagem festiva e apelativa que busca capturar a essência do bairro histórico de Lisboa e as suas tradicionais marchas populares. Através da inclusão do símbolo emblemático da fadista Severa e de elementos característicos das marchas e de Lisboa, como o corvo, o arco, o manjerico e a guitarra portuguesa, o logotipo transmite a alegria e o orgulho da comunidade da Mouraria a participar nesse evento cultural.<br>É uma representação visual marcante que encapsula festa, a tradição e o orgulho. É um convite para que todos se juntem à Marcha da Mouraria e vivam a experiência única das festas de Santo António.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Design de marca a Marcha da Mouraria',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Marcha da Mouraria'
    }
  },
  {
    id: 3,
    slug: 'green-pine',
    title: 'Green Pine Coffee & Store',
    subtitle: 'Espaço de lazer e comercial',
    mainImage: 'greenpine/GreenPine.jpg',
    type: 6,
    details: {
      images: [
        'greenpine/greenpine_1.jpg',
        'greenpine/greenpine_2.jpg',
        'greenpine/greenpine_3.jpg',
        'greenpine/greenpine_4.jpg',
        'greenpine/greenpine_5.jpg',
        'greenpine/greenpine_6.jpg'
      ],
      about: '<strong>Design de marca</strong> para os Hotéis e Spa Green Pine',
      project: 'Design de marca, Packaging design, design gráfico',
      client: 'Green Pine',
      date: '2021',
      country: 'Espanha',
      slogan: 'Good feelings',
      desc: 'No logotipo GreenPine, criamos um simbolo que quer pelas formas, quer pelas cores escolhidas procura aliar a natureza aos sentidos. Pretende-se em todas as peças de comunicação despertar sensações positivas ao cliente, nomeadamente a nivel olfativo numa associação ao espaço de jardim envolvente.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Design de marca Green Pine',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para os Hotéis e Spa, Green Pine'
    }
  },
  {
    id: 4,
    slug: 'booksfactory',
    title: 'Booksfactory',
    subtitle: 'Editora',
    mainImage: 'booksfactory/booksfactory.jpg',
    type: 7,
    details: {
      images: [
        'booksfactory/booksfactory_1.jpg',
        'booksfactory/booksfactory_2.jpg',
        'booksfactory/booksfactory_3.jpg',
        'booksfactory/booksfactory_4.jpg',
        'booksfactory/booksfactory_5.jpg',
        'booksfactory/booksfactory_6.jpg',
        'booksfactory/booksfactory_7.jpg',
        'booksfactory/booksfactory_8.jpg',
        'booksfactory/booksfactory_9.jpg'
      ],
      about: '<strong>Design de marca</strong> para a editora Booksfactory',
      project: 'Design de marca, Design interiores',
      client: 'Letras Impares',
      date: '2015',
      country: 'Portugal',
      slogan: 'A fábrica dos livros',
      desc: 'Ao conceber o logotipo para a BooksFactory, procurámos buscou uma abordagem única que unisse os elementos característicos da indústria editorial com a idea de produção criativa. O design desenvolvido representa a fusão de um livro e uma fábrica, criando uma metáfora visual que sugere a idea de criação, produção e fluxo de conhecimento.<br>A opção por formas simples e curvilíneas no logotipo da BooksFactory foi uma escolha consciente. Essas formas transmitem uma sensação de acessibilidade e aconchego, elementos que são essenciais no mundo da leitura e da literatura. A simplicidade das formas também facilita a rápida assimilação do logotipo, tornando-o mais facilmente reconhecível mesmo em tamanhos reduzidos.<br>A escolha do vermelho como cor predominant no logotipo da BooksFactory tem um propósito claro: atrair a atenção do consumidor. No entanto o seu tom é escuro, para transmitir igualmente sofisticação e cuidado.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca para a editora BooksFactory',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a editora BooksFactory.A fábrica dos livros'
    }
  },
  {
    id: 5,
    slug: 'instalmed',
    title: 'Instalmed',
    subtitle: 'Equipamento Médico',
    mainImage: 'instalmed/instalmed.jpg',
    type: 4,
    details: {
      images: [
        'instalmed/instalmed_1.jpg',
        'instalmed/instalmed_2.jpg',
        'instalmed/instalmed_3.jpg',
        'instalmed/instalmed_4.jpg',
        'instalmed/instalmed_5.jpg',
        'instalmed/instalmed_6.jpg',
        'instalmed/instalmed_7.jpg',
        'instalmed/instalmed_8.jpg'
      ],
      about: '<strong>Design de marca com coração</strong> Instalmed',
      project: 'Design de marca, Design gráfico, Design stand, Design digital',
      client: 'Instalmed - Equipamento médico',
      date: '2014',
      country: 'Angola',
      slogan: 'Com o coração na marca',
      desc: 'Para a instalmed, empresa de equipamentos cardiacos, escolhemos o coração para a simbolizar. As linhas intercaladas simbolizam o pulsar do coração, o pulsar das marcas reforçadas pelas duas cores opostas.'

    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Instalmed',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos para a Instalmed, Equipamentos cardiológicos em Design de marca com coração'
    }
  },
  {
    id: 6,
    slug: 'junkembo',
    title: 'Junkembo',
    subtitle: 'Restaurante - bar',
    mainImage: 'junkembo/junkembo.jpg',
    type: 5,
    details: {
      images: [
        'junkembo/junkembo_1.jpg',
        'junkembo/junkembo_2.png',
        'junkembo/junkembo_3.jpg',
        'junkembo/junkembo_4.png',
        'junkembo/junkembo_5.jpg',
        'junkembo/junkembo_6.jpg',
        'junkembo/junkembo_7.jpg',
        'junkembo/junkembo_8.jpg',
        'junkembo/junkembo_9.jpg',
        'junkembo/junkembo_10.jpg'
      ],
      about: '<strong>O homem boémio como símbolo da marca</strong> do Restaurante Junkembo',
      project: 'Design de marca, Design gráfico, estudo farda, Design produto',
      client: 'Restaurante Junkembo',
      date: '2017',
      country: 'Angola',
      slogan: 'O homem boémio',
      desc: 'Para símbolo do restaurante Junkembo, criamos a figura do homem boémio, alegre, despreocupado que gosta de conviver. É essa a essência do Restaurante Junkembo, um lugar para descontrair, para usufruir de bons momentos. <br>A cor azul particular transmite a energia positiva que se pretende vivenciar no espaço.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca  do Restaurante Junkembo.',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para o Restaurante Junkembo, baseado no homem boémio.'
    }
  },
  {
    id: 7,
    slug: 'capid',
    title: 'Capid',
    subtitle: 'Gestão de activos e investimentos',
    mainImage: 'capid/capid.jpg',
    type: 8,
    details: {
      images: [
        'capid/capid_1.jpg',
        'capid/capid_2.jpg',
        'capid/capid_3.png',
        'capid/capid_4.jpg',
        'capid/capid_6.png',
        'capid/capid_7.png',
        // 'capid/capid_7.jpg',
        // 'capid/capid_8.jpg',
        // 'capid/capid_9.jpg'
      ],
      about: '<strong>Design de marca</strong> para a empresa de activos e investimentos Capid',
      project: 'Design de marca, Design gráfico',
      client: 'Capid. Lda',
      date: '2021',
      country: 'Angola',
      slogan: 'Grifo, o protetor das riquezas.',
      desc: 'Para a representação da marca escolhemos o Grifo, figura mitológica que tem o corpo de leão e a cabeça e assas de uma águia. Simbolicamente os grifos são conhecidos por representarem as virtudes da sabedoria e da força. No entanto, na mitologia grega, o grifo pertencia a Zeus e protegia as suas riquezas.<br>No seu desenho optamos por linhas simples e elegantes. Através de um design mais minimalista conseguimos transmitir o grau de sofisticação que se pretende.<br>Haveria melhoria escolha para representar uma marca de gestão de investimentos?<br>O prestigio da marca é reforçado com as cores utilizadas, com um azul de prestigio e um laranja acobreado.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca  para a Empresa Capim',
      desc: 'Página de portfólio da MYLOGO. Conheça o nosso trabalho de criação de marca para a empresa de gestão de activos Capid. O grifo, protetor das riquezas escolhido como símbolo'
    }
  },
  {
    id: 8,
    slug: 'honest-turtle',
    title: 'Honest Turtle',
    subtitle: 'Audiovisuais',
    mainImage: 'honest/HonestTurtle.jpg',
    type: 9,
    details: {
      images: [
        'honest/HonestTurtle_1.jpg',
        'honest/HonestTurtle_2.jpg',
        'honest/HonestTurtle_3.jpg',
        'honest/HonestTurtle_4.jpg',
        'honest/HonestTurtle_5.jpg',
        'honest/HonestTurtle_6.jpg',
        'honest/HonestTurtle_7.jpg',
        'honest/HonestTurtle_8.jpg',
        'honest/HonestTurtle_9.jpg'
      ],
      about: '<strong>Design de marca</strong> para a empresa de audiovisuais Honest Turtle',
      project: 'Design de marca, Design gráfico',
      client: 'Honest Turtle',
      date: '2021',
      country: 'Portugal',
      slogan: 'Uma Tartaruga Amigável com um Toque Criativo',
      desc: 'Este logotipo é mais do que uma simples imagem; é a expressão visual da Honest Turtle e dos valores que defendem. Representa a nossa dedicação à honestidade, à qualidade e à criatividade em todos os projetos que empreendem.<br>A figura central do logotipo é uma tartaruga, um animal conhecido pela sua honestidade e determinação ao longo da jornada. A escolha das cores, laranja e preto, não foi por acaso. O laranja simboliza a energia criativa e a paixão que dedicam ao nosso trabalho, enquanto o preto representa a sofisticação e a qualidade dos serviços.<br>A carapaça da tartaruga assume a forma de uma máquina de filmar, incorporando o  compromisso com a excelência em produção audiovisual. Essa fusão de elementos naturais com tecnologia reflete uma abordagem única e inovadora no setor.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca  para a Honest Turtle - Audiovisuais',
      desc: 'Página de portfólio da MYLOGO. Conheça o nosso trabalho de criação de marca para a Honest Turtle. Uma Tartaruga Amigável com um Toque Criativo'
    }
  },
  {
    id: 9,
    slug: 'vitacentro',
    title: 'VitaCentro',
    subtitle: 'Clínicas',
    mainImage: 'vitacentro/vitacentro_0.jpg',
    type: 10,
    details: {
      images: [
        'vitacentro/vitacentro_1.jpg',
        'vitacentro/vitacentro_2.jpg',
        'vitacentro/vitacentro_3.jpg',
        'vitacentro/vitacentro_4.jpg',
        'vitacentro/vitacentro_5.jpg',
        'vitacentro/vitacentro_6.jpg',
        'vitacentro/vitacentro_7.jpg'
      ],
      about: '<strong>Design de marca</strong> VitaCentro - Clínicas',
      project: 'Design de marca, Design gráfico',
      client: 'Vitacentro',
      date: '2017',
      country: 'Portugal',
      slogan: 'Uma marca de juventude',
      desc: 'Para a marca Vitacentro, foi escolhido o círculo, simbolizando a perfeição, o eterno e o equilíbrio.  No seu centro a letra V. O centro da Vida. VitaCentro. Procurou-se que a marca transmitisse um espírito jovem, mas mantivesse um lado clássico, proforma a colocá-la num patamar de clínica dentária de topo. Esses princípios encontram-se igualmente refletidos nas opções de cor, desde o azul jovem e fresco, ao castanho mais formal.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Vitacentro',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos para a Vitacentro, Clinicas Dentárias em Criação de marca, Design comunicação, Design de montra. Criámos a Identidade gráfica, logotipo, estacionário, design de montra, merchandising. Uma marca de juventude.'
    }
  },
  {
    id: 10,
    slug: 'compadre',
    title: 'Compadre',
    subtitle: 'Cooking School',
    mainImage: 'compadre/Compadre_0.jpg',
    type: 11,
    details: {
      images: [
        'compadre/Compadre_1.jpg',
        'compadre/Compadre_2.jpg',
        'compadre/Compadre_3.jpg',
        'compadre/Compadre_4.jpg',
        'compadre/Compadre_5.jpg'
      ],
      about: '<strong>Design de marca</strong> Compadre - Cooking School',
      project: 'Design de marca, Design gráfico',
      client: 'Compadre - Cooking School',
      date: '2017',
      country: 'Portugal',
      slogan: 'Simplicidade e poder de atração',
      desc: 'O logotipo criado para a Escola, procura ser apresentação visual da paixão pela culinária.<br>A figura do chef, habilmente moldada para se encaixar na letra "O" de "Compadre", não  incorpora apenas a ideia de excelência culinária, mas também cria uma identidade única e memorável para a marca.<br>A escolha das cores foi feita com precisão. O preto transmite profissionalismo. Já o laranja traz consigo uma energia contagiante e entusiasmo pela culinária, despertando o apetite por novas experiências. A conjugação das duas, torna o laranja ainda mais vibrante.<br>O lettering foi especialmente elaborado com linhas simples e arredondadas. Esta escolha não garante apenas uma legibilidade perfeita em todos os formatos, mas também reflete a abordagem acolhedora e acessível da Compadre Cooking School. A simplicidade das formas transmite a sensação de familiaridade enquanto mantém uma estética moderna.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca  da Compadre Cooking School',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a escola de culinária Compadre .Simplicidade e poder de atração.'
    }
  },
  {
    id: 11,
    slug: 'wellsafe',
    title: 'Wellsafe',
    subtitle: 'Produtos farmacêuticos',
    mainImage: 'wellsafe/wellsafe_0.jpg',
    type: 12,
    details: {
      images: [
        'wellsafe/wellsafe_1.jpg',
        'wellsafe/wellsafe_2.jpg',
        'wellsafe/wellsafe_3.jpg',
        'wellsafe/wellsafe_4.jpg',
        'wellsafe/wellsafe_5.jpg',
        'wellsafe/wellsafe_6.jpg'
      ],
      about: '<strong>Design de marca</strong> para empresa farmacêutica',
      project: 'Design de marca, Packaging design, design gráfico',
      client: 'Farmácia Dolce Vita',
      date: '2014',
      country: 'Angola',
      slogan: 'Lado a lado, pelo bem estar.',
      desc: 'O logotipo Wellsafe nasce da combinação da inicial W da marca e o cliente. Missão da marca: Sorrisos e bem-estar dos clientes.<br>As formas arredondadas, cativam, transmitem felicidade e emoções positivas.<br>A forma do W permite reforçar a ideia da ligação entre a marca e o cliente, unidos para um objetivo comum.<br>'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca  da Wellsafe',
      desc: 'Página de portfólio da MYLOGO. Design de marca para a empresa farmacêutica Wellsafe .Design de marca, Packaging design, design gráfico.Lado a lado, pelo bem estar.'
    }
  },
  {
    id: 12,
    slug: 'aiwama',
    title: 'Aiwama',
    subtitle: 'Wine and food store',
    mainImage: 'aiwama/aiwama_0.jpg',
    type: 13,
    details: {
      images: [
        'aiwama/aiwama_1.jpg',
        'aiwama/aiwama_2.jpg',
        'aiwama/aiwama_3.jpg',
        'aiwama/aiwama_4.jpg',
        'aiwama/aiwama_5.jpg',
        'aiwama/aiwama_6.jpg',
        'aiwama/aiwama_7.jpg'
      ],
      about: '<strong>Design de marca</strong> Aiwama - Wine and food store',
      project: 'Design de marca, Packaging design, design gráfico',
      client: 'Aiwama',
      date: '2013',
      country: 'Angola',
      slogan: 'Tradição com um toque de modernidade',
      desc: 'A Aiwama, é um espaço dedicado ao vinho com serviços de consultoria vinícola, eventos, provas de vinho, espaço de petiscos e venda de acessórios.<br>O padrão étnico utilizado no logotipo foi cuidadosamente escolhido para transmitir a ideia de artesanal e tradicional, além de dar um toque de sofisticação e singularidade. A escolha de cores é composta por tons terrosos, como o castanho, o bege e o verde que evocam as cores da terra e do vinho, além de remeter a uma sensação de aconchego e conforto.<br>O design personalizado, a escolha cuidadosa de cores e o padrão étnico escolhido dão uma sensação de sofisticação, singularidade e versatilidade.<br>O logotipo é capaz de representar não apenas a venda de vinhos, mas todos os serviços oferecidos pela empresa, mantendo sempre sua identidade única.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca da Aiwama',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Wine anda food store, Aiwama .Tradição com um toque de modernidade'
    }
  },
  {
    id: 13,
    slug: 'patudos-e-barbatanas',
    title: 'Patudos e Barbatanas',
    subtitle: 'Produtos Veterinários',
    mainImage: 'patudos/patudos_0.jpg',
    type: 14,
    details: {
      images: [
        'patudos/patudos_1.jpg',
        'patudos/patudos_2.jpg',
        'patudos/patudos_3.jpg',
        'patudos/patudos_4.jpg',
        'patudos/patudos_5.jpg',
        'patudos/patudos_6.jpg',
        'patudos/patudos_7.png',
        'patudos/patudos_8.jpg',
        'patudos/patudos_9.jpg'
      ],
      about: '<strong>Design de marca</strong> Patudos e Barbatanas - Produtos Veterinário',
      project: 'Design de marca, Packaging design, design gráfico',
      client: 'Patudos e Barbatanas',
      date: '2017',
      country: 'Portugal',
      slogan: 'Conexão imediata com o consumidor.',
      desc: 'Para a Patudos e Barbatanas criámos três símbolos alusivos ao nome da empresa, demos lhe um nome e comunicámos a partir daí.<br>Uma marca simples que cativa muito facilmente o consumidor, através de uma simbologia directa e comunicativa.<br>A escolha das cores contribuiu igualmente para a afabilidade da nova marca.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Patudos e Barbatanas',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos para a Patudos e Barbatanas., produtos veterinários Criação de marca, Design comunicação. Conexão imediata com o consumidor.'
    }
  },
  {
    id: 14,
    slug: 'conhecer-angola',
    title: 'Conhecer Angola',
    subtitle: 'Guia Turístico',
    mainImage: 'conhecer-angola/conhecerangola_0.jpg',
    type: 15,
    details: {
      images: [
        'conhecer-angola/conhecerangola_1.jpg',
        'conhecer-angola/conhecerangola_2.jpg',
        'conhecer-angola/conhecerangola_3.jpg',
        'conhecer-angola/conhecerangola_4.png',
        'conhecer-angola/conhecerangola_5.jpg',
        'conhecer-angola/conhecerangola_6.png',
        'conhecer-angola/conhecerangola_7.jpg',
        'conhecer-angola/conhecerangola_8.jpg',
        'conhecer-angola/conhecerangola_9.jpg',
        'conhecer-angola/conhecerangola_10.jpg'
      ],
      about: '<strong>Design de marca</strong> Conhecer Angola - Guia Turístico',
      project: 'Design de marca, Design gráfico, Web design',
      client: 'Conhecer Angola',
      date: '2014',
      country: 'Angola',
      slogan: 'Um toque de angola em cada pormenor',
      desc: 'Para a marca do novo guia turístico a implementar em angola, optamos por um dos símbolos mais representativos do pais, a Palanca. À mesma adicionamos uma serie de elementos gráficos, que vulgarmente encontramos no vestuário africano.  Reforçamos a mensagem recorrendo às cores de Angola, o preto, vermelho e amarelo, numa combinação viva, alegre que juntamente com o símbolo, nos fazem querer conhecer Angola.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Design de marca Conhecer Angola',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos para o guia Turístico, Conhecer Angola. Design de marca, Design gráfico, web design. Um toque de angola em cada pormenor.'
    }
  },
  {
    id: 15,
    slug: 'treepox',
    title: 'Treepox',
    subtitle: 'Mesas de madeira e epoxi',
    mainImage: 'treepox/treepox_0.jpg',
    type: 17,
    details: {
      images: [
        'treepox/treepox_1.jpg',
        'treepox/treepox_2.jpg',
        'treepox/treepox_3.jpg',
        'treepox/treepox_4.jpg',
        'treepox/treepox_5.jpg',
        'treepox/treepox_6.jpg',
        'treepox/treepox_7.jpg',
        'treepox/treepox_8.jpg'
      ],
      about: '<strong>Design de marca</strong> Treepox - Mesas de madeira e epoxi',
      project: 'Design de marca, Design gráfico',
      client: 'Treepox',
      date: '2020',
      country: 'Portugal',
      slogan: 'Design do produto reflectido na marca',
      desc: 'A Treepox comercializa mesas de design único e exclusivo que alia a madeira maciça à resina epoxi, numa combinação de cor e requinte.<br> Para o design da marca, optamos por uma solução minimalista, jogando com o enlaçar dos dois “ee”, formando um símbolo muito simples, mas igualmente exclusivo e perfeito para representar a marca.<br> O dourado aliado ao cinza muito escuro ajuda a reforçar a elegância e excelência dos produtos.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Treepox',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Treepox'
    }
  },
  {
    id: 16,
    slug: 'essence',
    title: 'Essence',
    subtitle: 'Perfumaria',
    mainImage: 'essence/essence0.jpg',
    type: 18,
    details: {
      images: [
        'essence/essence1.jpg',
        'essence/essence2.jpg',
        'essence/essence3.jpg',
        'essence/essence4.jpg',
        'essence/essence5.jpg',
        'essence/essence6.jpg',
        'essence/essence7.jpg',
        'essence/essence8.jpg',
        'essence/essence9.jpg',
        'essence/essence10.jpg',
        'essence/essence11.jpg',
        'essence/essence12.jpg'
      ],
      about: '<strong>Design de marca</strong> Essence - Perfumaria',
      project: 'Design de marca, Packaging design, design gráfico, web design',
      client: 'Essence',
      date: 'Desde 2014',
      country: 'Angola',
      slogan: 'A marca que é o produto.',
      desc: 'Para a Essence que oferece ao consumidor perfumes de qualidade a preço acessível, a MYLOGO, desenvolveu uma logotipo tipográfico, minimalista, alusivo ao frasco de perfume que tanto se coloca ao alto, como deitado sobre uma prateleira. Os dois SS representam a onda da fragrância que se espalha pelo ar.<br>Toda marca foi trabalhada de um modo minimalista, mas impactante, com tons nobres dados pelo dourado em sobreposição à neutralidade dos brancos e pretos.<br>Uma marca que muda, transforma-se e adapta-se à evolução dos tempos.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Essence - Perfumaria',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Essence.'
    }
  },
  {
    id: 17,
    slug: 'clinica-de-colares',
    title: 'Clínica de Colares',
    subtitle: 'Clínica médica e Dentária',
    mainImage: 'clinicacolares/clinicacolares_0.jpg',
    type: 19,
    details: {
      images: [
        'clinicacolares/clinicacolares_1.jpg',
        'clinicacolares/clinicacolares_2.jpg',
        'clinicacolares/clinicacolares_3.jpg',
        'clinicacolares/clinicacolares_4.jpg',
        'clinicacolares/clinicacolares_5.jpg',
        'clinicacolares/clinicacolares_6.jpg',
        'clinicacolares/clinicacolares_7.jpg',
        'clinicacolares/clinicacolares_8.jpg',
        'clinicacolares/clinicacolares_9.jpg',
        'clinicacolares/clinicacolares_10.jpg',
        'clinicacolares/clinicacolares_11.jpg',
        'clinicacolares/clinicacolares_12.jpg'
      ],
      about: '<strong>Design de marca</strong> Clínica de Colares - Clinica médica e Dentária',
      project: 'Design de marca, Design gráfico',
      client: 'Clínica de Colares',
      date: '2016',
      country: 'Portugal',
      slogan: 'Uma marca suave e acolhedora.',
      desc: 'Para a Clínica de Colares, criámos um símbolo, que alia os dois “C” à natureza, da mesma forma que a clínica se integra no verde da serra que a envolve. O resultado final é uma imagem minimalista,  muito “clean”, com um toque de modernidade que se torna acolhedora e familiar.<br> Os tons são igualmente suaves, harmoniosos procurando transmitir sensações de bem-estar, conforto e tranquilidade.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Clínica de Colares - Clínica médica e Dentária',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Clínica de Colares - Clínica médica e Dentária.'
    }
  },
  {
    id: 18,
    slug: 'eon',
    title: 'Eon',
    subtitle: 'Soluções de Engenharia',
    mainImage: 'eon/eon_0.jpg',
    type: 16,
    details: {
      images: [
        'eon/eon_1.jpg',
        'eon/eon_2.jpg',
        'eon/eon_3.jpg',
        'eon/eon_4.jpg',
        'eon/eon_5.jpg',
        'eon/eon_6.jpg',
        'eon/eon_7.jpg',
        'eon/eon_8.jpg',
        'eon/eon_9.jpg',
        'eon/eon_10.jpg'
      ],
      about: '<strong>Design de marca</strong> Eon - Soluções de Engenharia',
      project: 'Design de marca, Design Gráfico',
      client: 'EON',
      date: '2014',
      country: 'Portugal',
      slogan: 'Dinamismo',
      desc: 'Na concepção da nova imagem da EON, optamos por uma imagem gráfica, dinâmica e original que se destaca do mercado. Inovação, dinamismo, proatividade, são transmitidas pelas cores, formas circulares e lettering arredondado. Tornar a engenharia mais próxima do cliente. <br>Todo o material estacionário foi cuidadosamente concebido de modo a preservar e reforçar a identidade criada.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Eon Engenharia',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de identidade para a Eon, Soluções de Engenharia. Dinamismo.'
    }
  },
  {
    id: 19,
    slug: 'bonabebe',
    title: 'Bonabebe',
    subtitle: 'Loja do bebé e da criança',
    mainImage: 'bonabebe/bonabebe_0.jpg',
    type: 20,
    details: {
      images: [
        'bonabebe/bonabebe_1.jpg',
        'bonabebe/bonabebe_2.jpg',
        'bonabebe/bonabebe_3.jpg',
        'bonabebe/bonabebe_4.jpg',
        'bonabebe/bonabebe_5.jpg'
      ],
      about: '<strong>Design de marca</strong> Bonabebe - Loja do bebé e da criança',
      project: 'Design de marca, Design Gráfico',
      client: 'Bonabebe',
      date: '2016',
      country: 'Portugal',
      slogan: 'Orgânico. Natural',
      desc: 'Para a marca Bonabebe, e um cliente que pretendia um símbolo distinto do habitual para este setor, a MYLOGO criou uma marca muito orgânica, através da comunhão da figura feminina e da natureza. Os tons pastel, reforçam esse cunho natural.<br>O símbolo transporta-nos à criação, ao início e à universalidade.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Conheça o nosso trabalho. Bonabebe',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de identidade para a Bonabebe'
    }
  },
  {
    id: 20,
    slug: 'empreendedores-com-pt',
    title: 'Empreendedores.com.pt',
    subtitle: 'Revista e conteúdos digitais',
    mainImage: 'empreendedores/empreendedores_0.png',
    type: 21,
    details: {
      images: [
        'empreendedores/empreendedores_1.jpg',
        'empreendedores/empreendedores_2.jpg',
        'empreendedores/empreendedores_3.jpg',
        'empreendedores/empreendedores_4.jpg',
        'empreendedores/empreendedores_5.jpg',
        'empreendedores/empreendedores_6.jpg',
        'empreendedores/empreendedores_7.jpg',
        'empreendedores/empreendedores_8.jpg'
      ],
      about: '<strong>Design da marca</strong> <br>Empreendedores.com.pt - Revista e conteúdos digitais.',
      project: 'Design de marca, Design Gráfico, Design de produto',
      client: 'Empreendedores.com.pt',
      date: 'Desde 2012',
      country: 'Portugal',
      slogan: 'Jornalismo - Tipografia',
      desc: 'Para dar resposta à necessidade de uma imagem que não simbolizasse nenhuma área em detrimento da outra, a MYLOGO, optou por um logotipo tipográfico, que reforça a vertente jornalística e onde se destacam os dois “ee”, em posição de ascensão, simbolizando o crescimento e o empreendedorismo.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca Empreendedores.com.pt - Revista e conteúdos digitais.',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Empreendedores.com.pt - Revista e conteúdos digitais.'
    }
  },
  {
    id: 21,
    slug: 'bazza',
    title: 'Bazza',
    subtitle: 'Serviço de transportes e entregas expresso',
    mainImage: 'bazza/bazza_0.png',
    type: 22,
    details: {
      images: [
        'bazza/bazza_1.jpg',
        'bazza/bazza_2.jpg',
        'bazza/bazza_3.jpg',
        'bazza/bazza_4.jpg',
        'bazza/bazza_5.jpg',
        'bazza/bazza_6.jpg',
        'bazza/bazza_7.jpg',
        'bazza/bazza_8.jpg'
      ],
      about: '<strong>Design da marca</strong> <br>Bazza - Serviço de transportes e entregas expresso.',
      project: 'Design de marca, design gráfico, web design',
      client: 'Bazza',
      date: '2021',
      country: 'Angola',
      slogan: 'Uma marca em movimento.',
      desc: 'Para o design da marca, criamos um símbolo que alia os dois Z, e representa o conceito de movimento e dinamismo que se pretende transmitir. A cor laranja amplia essa ideia, transmitindo energia, alegria, jovialidade e dinamismo.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca Bazza - Serviço de transportes e entregas expresso.',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Bazza - Serviço de transportes e entregas expresso.'
    }
  },
  {
    id: 22,
    slug: 'canal-cultura',
    title: 'Canal Cultura',
    subtitle: 'Plataforma streaming de conteúdos culturais',
    mainImage: 'canalcultura/canalcultura_0.jpg',
    type: 23,
    details: {
      images: [
        'canalcultura/canalcultura_1.jpg',
        'canalcultura/canalcultura_2.png',
        'canalcultura/canalcultura_3.png',
        'canalcultura/canalcultura_6.png',
        'canalcultura/canalcultura_7.png',
        'canalcultura/canalcultura_8.png',
        'canalcultura/canalcultura_7.jpg',
        'canalcultura/canalcultura_8.jpg',
        'canalcultura/canalcultura_9.jpg'
      ],
      about: '<strong>Design da marca</strong> <br>Canal Cultura - Plataforma streaming de conteúdos culturais',
      project: 'Design de marca, Design gráfico digital, publicidade',
      client: 'Letras Impares',
      date: 'Desde 2021',
      country: 'Portugal',
      slogan: 'A cor da cultura',
      desc: 'Para o Canal Cultura a MYLOGO desenhou um logotipo repleto de símbolos. À primeira vista, identificamos os dois “C” de Canal Cultura unidos. No entanto, consegue-se identificar na mesma imagem, a representação de dois olhos, os do espectador e a representação do publico, com duas figuras sentadas, uma atrás da outra.<br>Apesar de ser um logotipo repleto de significados, a sua forma é simples o que o torna fácil de lembrar, característica fundamental para a implementação com sucesso de um canal de streaming<br>A nível cromático optamos por um degrade que reforça a ideia de canal, de algo que flui.<br>A paleta de cores utilizada vai dos laranjas ao roxo, cores fortes, de simbolismo muito ligado à cultura, com significados de paixão, excitação, criatividade, nobreza, sucesso.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca Canal Cultura - Plataforma streaming de conteúdos culturais',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Canal Cultura - Plataforma streaming de conteúdos culturais'
    }
  },
  {
    id: 23,
    slug: 'ingredient-odyssey',
    title: 'Ingredient Odyssey',
    subtitle: 'Investigação e Desenvolvimento',
    mainImage: 'ingredientodyssey/IO_0.png',
    type: 24,
    details: {
      images: [
        'ingredientodyssey/io_1.png',
        'ingredientodyssey/io_2.png',
        'ingredientodyssey/io_3.jpg',
        'ingredientodyssey/io_4.jpg',
        'ingredientodyssey/io_5.jpg',
        'ingredientodyssey/io_6.jpg',
      ],
      about: '<strong>Design da marca</strong> <br>Ingredient Odyssey - Investigação e Desenvolvimento',
      project: 'Design de marca, Design gráfico',
      client: 'Ingredient Odyssey',
      date: '2019',
      country: 'Portugal',
      slogan: 'O ADN de uma marca',
      desc: 'A Ingredient Odyssey, tem no seu ADN a sustentabilidade ambiental, através do desenvolvimento de alternativas proteicas para a alimentação humana e animal.<br>A ideia por detrás da imagem desenvolvida remete aos tempos das descobertas, quando os portugueses percorriam o mundo nas suas caravelas em busca dos alimentos e especiarias mais raras e valiosas. Assim nasce a marca gráfica , em formato emblema, como um selo de qualidade, inovação e espirito empreendedor.'
    },
    meta: {
      title: 'Portfólio MYLOGO – Design da marca Ingredient Odyssey - Investigação e Desenvolvimento',
      desc: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Ingredient Odyssey - Investigação e Desenvolvimento'
    }
  },
]

export { allProjects }
