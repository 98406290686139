<template>
    <div>

		<!-- Page Title -->
		<section data-uk-scrollspy="cls:uk-animation-fade" class="image-bg-26-- overlay-white padding-top-bottom-100px">

			<!-- Container -->
			<div class="container">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-md-10 col-sm-12 col-xs-12 text-center margin-auto">

						<div class="uk-dark padding-70px all-border border-10px border-color-black width-60 margin-auto xs-width-100">

							<!-- Heading -->
							<h3 class="text-weight-700 text-black no-margin-bottom">404</h3>

							<div class="separator center-col width-100-percent bottom-border border-1px border-color-gray-dark margin-top-bottom-20px margin-bottom-25px"></div>

							<h5 class="text-extra-small text-black text-weight-700 margin-bottom-10px text-uppercase letter-spacing-10">Página não encontrada</h5>

						</div>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</section>
		<!-- End Page Title -->

		<!-- Call to Action -->
		<section data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-bottom-150px bg-black">

			<!-- Container -->
			<div class="container">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-md-12 col-sm-12 col-xs-12 center-col text-center margin-auto">

						<h6 class="montserrat text-weight-600 text-white no-margin-bottom">Tem um projeto em mente para nós concretizarmos?<a class="btn btn-medium btn-transparent-white border-radius-50 margin-left-20px md-margin-top-35px sm-margin-left-right-auto sm-display-table" href="#">Contacte-nos</a></h6>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</section>
		<!-- End Call to Action -->
		<footer-main-component></footer-main-component>
    </div>
</template>

<script>
import FooterMainComponent from './FooterMainComponent.vue'

export default {
    components: {
		FooterMainComponent
    }
}
</script>