<template>
    <div>

		<!-- Portfolio -->
		<section data-uk-scrollspy="cls:uk-animation-fade" class="">

			<!-- Container -->
			<div class="container-full">

				<!-- Portfolio Grid -->
				<div data-uk-filter="target: .works">

					<!-- Container -->
					<!-- <div class="container">

						<!-- Filter -->
						<!-- <ul class="uk-subnav filter-style-4 margin-bottom-50px"> -->

							<!-- <li class="uk-active"  data-uk-filter-control><a class="btn btn-small uk-button-default" href="#">All</a></li>
							<li data-uk-filter-control="filter: .images"><a class="btn btn-small uk-button-default" href="#">Images</a></li>
							<li data-uk-filter-control="filter: .projects"><a class="btn btn-small uk-button-default" href="#">Projects</a></li>

						</ul> -->
						<!-- End Filter -->

					<!-- </div> -->

					<!-- Work -->
					<div class="works uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-4@l uk-text-center uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls: uk-animation-slide-left-medium; target: > div > a; delay: 50; repeat: false">

						<!-- Work -->
						<div v-for="project in projects" class="projects" :key="project.id">
							<a :href="'portfolio/'+project.slug" class="uk-inline-clip uk-transition-toggle" tabindex="0">
							<!-- <router-link :to="{ name: 'Details', params:{slug: project.slug} }" class="uk-inline-clip uk-transition-toggle" tabindex="0"> -->

								<!-- Image -->
								<img :src="'images/works/'+project.mainImage" alt="" />

								<!-- Hover -->
								<div class="uk-transition-slide-left uk-position-left text-left bg-white width-75">

									<!-- Hover Container -->
									<div class="margin-left-fifteen-percent margin-right-fifteen-percent">

										<!-- Top Content -->
										<div class="uk-position-top padding-30px">

											<h5 class="text-weight-100 text-black margin-bottom-15px">{{project.title}}</h5>

											<p class="no-margin-bottom text-weight-400 text-gray-dark">{{project.subtitle}}</p>

										</div>
										<!-- End Top Content -->

										<!-- Bottom Icons -->
										<div class="hover-icon-01 uk-position-bottom padding-30px">

											<span class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px" data-uk-icon="icon: image; ratio: 0.8"></span>

											<span class="bg-black text-white width-30px height-30px line-height-28 border-radius-100 text-center margin-right-10px" data-uk-icon="icon: link; ratio: 0.8"></span>

										</div>
										<!-- End Bottom Icons -->

									</div>
									<!-- End Hover Container -->

								</div>
								<!-- End Hover -->

							</a>
							<!-- </router-link> -->

						</div>
						<!-- End Work -->

					</div>

				</div>

			</div>
			<!-- End Container -->

		</section>
		<!-- End Portfolio -->

		<footer-main-component></footer-main-component>

    </div>
</template>

<script>
import FooterMainComponent from './FooterMainComponent.vue'
import { allProjects } from '../projects'

export default {
  components: {
    FooterMainComponent
  },
  data () {
    return {
      projects: allProjects
    }
  },
  created () {
  },
  metaInfo: {
    title: 'Portfólio MYLOGO– Conheça o nosso trabalho.',
    meta: [
      { charset: 'utf-8' },
      { vmid: 'description', name: 'description', content: 'Página de portfólio da MYLOGO. Conheça o nosso trabalho e as peças que nos orgulhamos de criar. Porque todas as empresas merecem uma grande imagem.' }
    ]
  }
}
</script>
