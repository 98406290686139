<template>
    <div>

		<!-- About -->
		<section id="link1" class="padding-top-bottom-150px md-padding-top-bottom-70px">

			<!-- Container -->
			<div class="container" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > .row > div ; delay: 50; repeat: false">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

						<h2 class="text-weight-300 text-black">O que podemos fazer pelo seu projeto? <span class="text-weight-500">Vamos impulsionar o seu negócio</span></h2>

						<p>Ao criar uma identidade visual profissional e impactante para a sua empresa, iremos inevitavelmente estimular o seu negócio, aumentar a sua credibilidade, transmitir maior profissionalismo, diferenciar da concorrência, fidelizar clientes e atrair novos.<br>
							Convencido que são fatores mais que suficientes para apostar num design profissional?</p>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</section>
		<!-- End About -->

		<!-- Stats -->
		<section class="bg-gray-extra-light">

			<!-- Container -->
			<div class="container-full" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > .row > div ; delay: 50; repeat: false">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-md-6 col-sm-12 col-xs-12 center-col text-center no-padding margin-auto image-bg-mylogo-1">

						<img src="images/identidadevisual.jpg" class="md-object-cover xs-height-auto height-100" alt="Identidade Visual" />

					</div>
					<!-- End Column -->

					<!-- Column -->
					<div class="col-md-6 col-sm-12 col-xs-12 center-col bg-gray-extra-light-2 text-left padding-top-40px padding-bottom-40px md-padding-top-bottom-40px margin-auto sm-text-center">

						<div class="row">

							<div class="col-md-11 col-sm-12 col-xs-12 center-col">

								<div class="container">

									<div class="">

										<h2 class="text-weight-500 text-white line-height-1">Criamos a identidade visual <span class="text-weight-300">da sua marca, projeto ou empresa</span></h2>
										<p class="text-white">A identidade visual da marca não é apenas o seu logotipo, mas sim um conjunto de elementos gráficos que são usados para representar a empresa e transmitir a sua mensagem de maneira consistente e coerente em todos os meios de comunicação.</p>

									</div>

									<div class="padding-top-bottom-20px">

										<h3 class="text-weight-600 text-white line-height-1 xs-text-extra-large-5 text-extra-large-6">Áreas de atuação</h3>
										<h4 class="text-weight-600 text-white line-height-1 xs-text-extra-large-4 text-extra-large-5 margin-bottom-10px">Criação de logotipo</h4>
										<p class="text-white">Desenvolvimento e um logotipo único e memorável que capture a essência da marca e seusvalores.</p>
										<h4 class="text-weight-600 text-white line-height-1 xs-text-extra-large-4 text-extra-large-5 margin-bottom-10px">Manual de identidade visual</h4>
										<p class="text-white">Guia de diretrizes de uso, incluindo especificações técnicas, paleta de cores, tipografia, elementos gráficos.</p>
										<h4 class="text-weight-600 text-white line-height-1 xs-text-extra-large-4 text-extra-large-5 margin-bottom-10px">Design gráfico para impressão</h4>
										<p class="text-white">Design de peças desfiz cartões de visita, papel timbrado, folhetos, catálogos, apresentações institucionais, entre outras.</p>
										<h4 class="text-weight-600 text-white line-height-1 xs-text-extra-large-4 text-extra-large-5 margin-bottom-10px">Design de materiais digitais</h4>
										<p class="text-white">Banners, posts para redes sociais, assinaturas para email, newsletter e websites.</p>
									</div>

								</div>

							</div>

						</div>

					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</section>
		<!-- End Stats -->

		<!-- Process Steps -->
		<section data-uk-scrollspy="cls:uk-animation-fade" class="bg-gray-extra-light xs-padding-top-bottom-50px padding-top-bottom-150px uk-scrollspy-inview uk-animation-fade" style="">
		
			<!-- Container -->
			<div class="container">
			
				<!-- Row -->
				<div class="row">
				
					<!-- Column -->
					<div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-bottom-100px">
						
						<!-- Heading -->
						<h2 class="text-weight-400 text-black no-margin-bottom">O nosso <span class="text-weight-500">processo de criação</span></h2>
						
					</div>
					<!-- End Column -->
				
				</div>
				<!-- End Row -->
	
				
				<!-- Row -->
				<div class="row">
				
					<!-- Column -->
					<div class="col-md-12 col-sm-12 col-xs-12 text-center center-col margin-auto">
						
						<!-- Positioning -->
						<div class="position-relative overflow-hidden width-100 padding-150px-bottom" data-uk-scrollspy="cls: uk-animation-scale-up; target: > .row > div ; delay: 50; repeat: false">

							<!-- Row -->
							<div class="row">
							

								<!-- Column -->
								<div class="col-md-3 col-sm-12 col-xs-12 sm-margin-bottom-50px uk-scrollspy-inview uk-animation-scale-up" style="">
								
									<!-- Number -->
									<h6 class="uk-heading-line text-left text-large sm-text-center no-margin-bottom"><span class="bg-black border-radius-100-percent width-60px height-60px line-height-60 text-white text-weight-500 text-center">01</span></h6>
									
									<!-- Title -->
									<p class="montserrat text-gray-extra-dark text-medium text-uppercase text-left sm-text-center text-weight-800 letter-spacing-2 margin-top-15px margin-bottom-10px">Briefing</p>
									
									<!-- Text -->
									<p class="text-left text-small text-gray-extra-dark text-weight-400 no-margin-bottom sm-text-center">Após o preenchimento de um pequeno questionário, que servirá para conhecermos o projeto, enviaremos uma proposta de orçamento personalizada.<br> Depois falta apenas sinalizar e estamos prontos para criar!</p>

								</div>
								<!-- End Column -->

								
								<!-- Column -->
								<div class="col-md-3 col-sm-12 col-xs-12 sm-margin-bottom-50px uk-scrollspy-inview uk-animation-scale-up" style="">
								
									<!-- Number -->
									<h6 class="uk-heading-line text-center text-large no-margin-bottom sm-text-center"><span class="bg-black border-radius-100-percent width-60px height-60px line-height-60 text-white text-weight-500 text-center">02</span></h6>
									
									<!-- Title -->
									<p class="montserrat text-gray-extra-dark text-medium text-uppercase text-center text-weight-800 letter-spacing-2 margin-top-15px margin-bottom-10px sm-text-center">Tempo para criar</p>
									
									<!-- Text -->
									<p class="text-center text-small text-gray-extra-dark text-weight-400 no-margin-bottom sm-text-center">Vamos analisar a concorrência, pesquisar, esboçar, criar soluções, escolhendo a tipografia e cor certas. No final apresentaremos as duas melhores propostas para a sua marca.</p>

								</div>
								<!-- End Column -->
								
								
								<!-- Column -->
								<div class="col-md-3 col-sm-12 col-xs-12 sm-margin-bottom-50px uk-scrollspy-inview uk-animation-scale-up" style="">
								
									<!-- Number -->
									<h6 class="uk-heading-line text-center text-large no-margin-bottom sm-text-center"><span class="bg-black border-radius-100-percent width-60px height-60px line-height-60 text-white text-weight-500 text-center">03</span></h6>
									
									<!-- Title -->
									<p class="montserrat text-gray-extra-dark text-medium text-uppercase text-center text-weight-800 letter-spacing-2 margin-top-15px margin-bottom-10px sm-text-center">Acertos</p>
									
									<!-- Text -->
									<p class="text-center text-small text-gray-extra-dark text-weight-400 no-margin-bottom sm-text-center">Esta é a fase, em que em conjunto consigo, podemos fazer pequenos acertos, para ficar perfeito.<br>Depois estamos prontos, se quiser, para desenvolver toda a identidade visual.</p>

								</div>
								<!-- End Column -->

								<!-- Column -->
								<div class="col-md-3 col-sm-12 col-xs-12 uk-scrollspy-inview uk-animation-scale-up" style="">
								
									<!-- Number -->
									<h6 class="uk-heading-line text-right text-large no-margin-bottom sm-text-center"><span class="bg-black border-radius-100-percent width-60px height-60px line-height-60 text-white text-weight-500 text-center">04</span></h6>
									
									<!-- Title -->
									<p class="montserrat text-gray-extra-dark text-medium text-right text-uppercase text-weight-800 letter-spacing-2 margin-top-15px margin-bottom-10px sm-text-center">Entrega</p>
									
									<!-- Text -->
									<p class="text-gray-extra-dark text-small text-right text-weight-400 no-margin-bottom sm-text-center">Etapa de executar as artes finais nos diversos formatos de gravação. Vamos também preparar o manual de normas, criado especialmente para o seu projeto.<br>
										Irá receber as suas artes finais assim que o valor remanescente for pago.
									</p>

								</div>
								<!-- End Column -->
								
							</div>
							<!-- End Row -->
							
						</div>
						<!-- End Positioning -->
						
					</div>
					<!-- End Column -->
					
				</div>
				<!-- End Row -->
				
			</div>
			<!-- End Container -->

		</section>
		<!-- END Process Steps -->

		<!-- Call to Action Style 01 -->
		<section class="padding-top-bottom-150px md-padding-top-bottom-70px bg-mylogo">

			<!-- Container -->
			<div class="container">

				<!-- Row -->
				<div class="row">

					<!-- Column -->
					<div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

						<h5 class="barlow-condensed text-extra-large-1 text-weight-600 text-gray-extra-dark margin-bottom-35px">Bons projetos nascem de fortes parcerias. <br>Vamos trabalhar juntos?</h5>

						<!-- Button -->
						<div style="" class="uk-scrollspy-inview uk-animation-slide-bottom">

							<a href="/contactos" class="btn btn border-radius-50 btn-small btn-black md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Contacte-nos</a>

						</div>
						<!-- End Button -->
					</div>
					<!-- End Column -->

				</div>
				<!-- End Row -->

			</div>
			<!-- End Container -->

		</section>
		<!-- End Call to Action Style 01 -->
		<footer-main-component></footer-main-component>
    </div>
</template>

<script>
import ContactoPopUpComponent from './ContactoPopUpComponent.vue'
import FooterMainComponent from './FooterMainComponent.vue'

export default {
  components: {
    ContactoPopUpComponent,
    FooterMainComponent
  },
  metaInfo: {
    title: 'SERVIÇOS MYLOGO. Criação de marcas Identidades visuais profissionais e importantes.',
    meta: [
      { charset: 'utf-8' },
      { vmid: 'description', name: 'description', content: 'Página de serviços MYLOGO. Criação de Marca Identidade visual. Impulsionamos o seu negócio. Naming, logotipo, estacionário, design gráfico para impressão, design de materiais digitais.divulgação institucional, publicidade, packaging, websites, gestão redes sociais, anunciar na web' }
    ]
  },
  created () {
  }
}

</script>
