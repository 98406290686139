<template>
    <div class="details">
      <!-- Project Section -->
      <images2 :images="['cal/cal_1.jpg', 'cal/cal_2.jpg']"></images2>
      <!------------------ABOUT------------------------------------------------->
      <div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-150px padding-bottom-150px">

        <div class="container">

          <!-- Row -->
          <div class="row justify-content-center" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

            <!-- Column -->
            <div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

              <h2 class="text-weight-100 text-gray-extra-dark margin-bottom-35px"><strong>Design da marca</strong> <br>CAL - Clube dos Amigos do Livro.</h2>

            </div>
            <!-- End Column -->

          </div>
          <!-- End Row -->

        </div>
        <!-- End Container -->

      </div>
      <!------------------ABOUT------------------------------------------------->
      <images3 :images="['cal/cal_3.jpg', 'cal/cal_4.jpg',  'cal/cal_5.jpg']"></images3>
      <project-details project="Design de marca, Design Gráfico" client="Letras ímpares, Lda" date="Desde 2020" country="Portugal"></project-details>
      <images2 :images="['cal/cal_6.jpg', 'cal/cal_7.jpg']"></images2>
      <full-image :images="['cal/cal_8.jpg']"></full-image>
      <!------------------DESC------------------------------------------------->
      <div data-uk-scrollspy="cls:uk-animation-fade" class="margin-bottom-150px margin-top-150px">
        <div class="container">
          <div class="row" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">
            <div class="col-md-12 col-sm-12 col-xs-12 center-col text-center">
              <h5 class="text-weight-500 text-gray-extra-dark">Livros e leitores - A aliança perfeita.</h5>
              <div class="width-100 sm-width-100">
                <p class="text-gray-dark">O logotipo do CAL, foi criado com o objetivo de transmitir uma imagem alegre, atrativa e multigeracional. A idea central do simbolo é representar as lombadas dos livros em conjunto com a sigla CAL.<br>A imagem busca destacar o prazer da leitura, abrangendo tanto os leitores mais jovens quanto os mais velhos, estabelecendo um vínculo entre o leitor e a marca que reforça a ideia de um clube. O design foi cuidadosamente desenvolvido para transmitir a sensação de pertença a uma comunidade de amantes da leitura.<br>As cores escolhidas para o logotipo são alegres e vibrantes, trazendo vivacidade à marca.</p>
              </div>
            </div>
          </div>
        </div>
	    </div>
      <!------------------DESC------------------------------------------------->
      <div class="pager text-center margin-top-150px">
        <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px"> PROJETO Anterior</a>
        <a href="#" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px">PROJETO Seguinte</a>
        <!-- <router-link :to="prevProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Anterior</router-link>
        <router-link :to="nextProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Próximo</router-link> -->
      </div>
      
      <footer-main-component></footer-main-component>
    </div>
</template>

<script>
import FooterMainComponent from './../FooterMainComponent.vue'
import Images2 from './../projects/types/2images.vue'
import Images3 from './../projects/types/3images.vue'
import ProjectDetails from './../projects/types/details.vue'
import FullImage from './../projects/types/fullimage.vue'
import { allProjects } from '../../projects'

export default {
  name: 'Details',
  components: {
    FooterMainComponent,
    Images2,
    Images3,
    ProjectDetails,
    FullImage
  },
  data: function () {
    return {
      projects: allProjects
    }
  },
  methods: {
    getProject: function (projects) {
      return this.projects.filter(item => !item.slug.toLowerCase().indexOf(this.slug))
    },
    nextProject: function (projects) {
    	if (this.filterProject[0].id + 1 >= this.projects.length) {
    		return this.projects[0].slug
      } else {
        return this.projects[this.filterProject[0].id + 1].slug
      }
    },
    prevProject: function (projects) {
    	if (this.filterProject[0].id - 1 <= 0) {
        return this.projects[this.projects.length - 1].slug
      } else {
        return this.projects[this.filterProject[0].id - 1].slug
      	}
    }
  },
  computed: {
    filterProject: function () {
      return this.getProject(this.projects)
    }
  },
  metaInfo () {
    return {
	 title: 'teste',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'teste'
      }]
    }
  }
}
</script>
